<template>
  <h1
    v-if="pageTitle"
    class="text-xl font-bold truncate max-w-[60vw]"
    :class="useGradientBanner ? 'text-white' : 'text-black'">
    {{ pageTitle }}
  </h1>

  <div
    v-else
    class="h-4 w-80 animate-pulse rounded bg-slate-200"
  />
</template>

<script setup>

import {computed} from "vue";
import {useRoute} from "vue-router";
import {usePageMetaStore} from "../stores/PageMetaStore";

defineProps({
  useGradientBanner: Boolean,
});

const route = useRoute();
const pageMetaStore = usePageMetaStore();

const pageTitle = computed(() => route.meta.title || pageMetaStore.pageTitle);
</script>
