import {BuildingOffice2Icon, Cog6ToothIcon, EyeIcon, UserIcon, StarIcon} from "@heroicons/vue/24/solid/index.js";

export const RECRUITER = "Recruiter";
export const COMPANY_ADMIN = "Company Admin";
export const MANAGER = "Manager";
export const TEAM_MEMBER = "Team Member";

export const ENTERPRISE_OWNER = "Enterprise Owner";
export const ENTERPRISE_ADMIN = "Enterprise Admin";
export const ENTERPRISE_VIEWER = "Enterprise Viewer";

const getCompanyAdmin = (nouns = {}) => ({
  iconIsComponent: true,
  icon: BuildingOffice2Icon,
  title: `${nouns?.companyNameSingular || "Company"} Admin`,
  description: `All ${nouns?.companyNameSingular || "Company"} Manager permissions, plus billing access.`,
  value: COMPANY_ADMIN,
  iconClasses: "text-bdYellow-600 h-4 w-4 self-center min-w-[1rem]"
});

const companyAdmin = getCompanyAdmin();

const getManager = (nouns = {}) => ({
  iconIsComponent: true,
  icon: Cog6ToothIcon,
  title: `${nouns?.companyNameSingular || "Company"} Manager`,
  description: `All ${nouns?.companyNameSingular || "Company"} Recruiter permissions, plus ability to manage other managers/recruiters at a ${nouns?.companyNameSingular?.toLowerCase() || "company"} and edit the ${nouns?.companyNameSingular?.toLowerCase() || "company"} profile.`,
  value: MANAGER,
  iconClasses: "text-bdOrange-600 h-4 w-4 self-center min-w-[1rem]"
});

const manager=  getManager();

const getRecruiter = (nouns = {}) => ({
  iconIsComponent: true,
  icon: UserIcon,
  title: `${nouns?.companyNameSingular || "Company"} Recruiter`,
  description: `Ability to create opportunities, move candidates, and create offers at a ${nouns?.companyNameSingular?.toLowerCase() || "company"}.`,
  value: RECRUITER,
  iconClasses: "text-bdPurple-600 h-4 w-4 self-center min-w-[1rem]"
});

const recruiter = getRecruiter();

const getTeamMember = (nouns = {}) => ({
  iconIsComponent: true,
  icon: EyeIcon,
  title: `${nouns?.companyNameSingular || "Company"} View-Only`,
  description: `Ability to view basic details at a ${nouns?.companyNameSingular?.toLowerCase() || "company"}.`,
  value: TEAM_MEMBER,
  iconClasses: "text-bdBlue-600 h-4 w-4 self-center min-w-[1rem]"
});

const teamMember = getTeamMember();

const enterpriseOwner = (nouns) => ({
  iconIsComponent: true,
  icon: StarIcon,
  title: `${nouns.typeName} Account Owner`,
  description: `Ability to view and manage the entire ${nouns?.groupPlural || "Enterprise"} Account.`,
  value: ENTERPRISE_OWNER,
  iconClasses: "text-bdYellow-600 h-4 w-4 self-center min-w-[1rem]"
});

const enterpriseAdmin = (nouns) => ({
  iconIsComponent: true,
  icon: BuildingOffice2Icon,
  title: `${nouns.groupSingular} Admin`,
  description: `Ability to view and manage individual ${nouns?.groupPlural?.toLowerCase() || "groups"} they're assigned to.`,
  value: ENTERPRISE_ADMIN,
  iconClasses: "text-bdOrange-600 h-4 w-4 self-center min-w-[1rem]"
});

const enterpriseViewer = (nouns) => ({
  iconIsComponent: true,
  icon: EyeIcon,
  title: `${nouns.groupSingular} View-Only`,
  description: `Ability to view info about ${nouns?.groupPlural?.toLowerCase() || "groups"} they're assigned to.`,
  value: ENTERPRISE_VIEWER,
  iconClasses: "text-bdBlue-600 h-4 w-4 self-center min-w-[1rem]"
});

export const getEnterpriseRolesWithDescriptions = (nouns) => [enterpriseOwner(nouns), enterpriseAdmin(nouns), enterpriseViewer(nouns)];

export const roleHierarchyWithDescriptions = {
  [COMPANY_ADMIN]: [companyAdmin, manager, recruiter, teamMember],
  [MANAGER]: [manager, recruiter, teamMember],
  [RECRUITER]: [recruiter, teamMember],
  [TEAM_MEMBER]: [teamMember]
};

export const enterpriseRoleHierarchyWithDescriptions = (nouns) => ({
  [ENTERPRISE_OWNER]: [enterpriseOwner(nouns), enterpriseAdmin(nouns), enterpriseViewer(nouns)],
  [ENTERPRISE_ADMIN]: [enterpriseAdmin(nouns), enterpriseViewer(nouns)],
  [ENTERPRISE_VIEWER]: [enterpriseViewer(nouns)]
});

export const enterpriseCompanyRoleHierarchyWithDescriptions = (nouns) => ({
  [COMPANY_ADMIN]: [getCompanyAdmin(nouns), getManager(nouns), getRecruiter(nouns), getTeamMember(nouns)],
  [MANAGER]: [getManager(nouns), getRecruiter(nouns), getTeamMember(nouns)],
  [RECRUITER]: [getRecruiter(nouns), getTeamMember(nouns)],
  [TEAM_MEMBER]: [getTeamMember(nouns)]
});
