export const setItem = (name, value, hours) => {
  const date = new Date();

  date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
  const expires = date.toUTCString();

  localStorage.setItem(name, JSON.stringify({ value, expires }));
};

export const getItem = (name) => {
  const stored = localStorage.getItem(name);
  if (!stored) return null;

  const { value, expires } = JSON.parse(stored);
  const now = new Date();

  if (new Date(expires) < now) {
    localStorage.removeItem(name);
    return null;
  }
  return value;
};

export const removeItem = (name) => {
  localStorage.removeItem(name);
};

export const getArray = (name) => {
  return getItem(name) || [];
};

export const pushToArray = (name, value, days) => {
  let existingArray = getItem(name);

  if (!Array.isArray(existingArray)) {
    existingArray = [];
  }

  if (!existingArray.includes(value)) {
    existingArray.push(value);
    setItem(name, existingArray, days * 24);
  }

  return existingArray;
};
