<template>
  <MenuContainer
    is-enterprise
    :menu-items="menuItems"
    dashboardLink="/enterprise-groups"
    accountLink="/enterprise-account"
  >
    <slot/>
  </MenuContainer>
</template>

<script setup>
import MenuContainer from "../components/MenuContainer.vue";
import {
  BuildingLibraryIcon,
  BuildingOffice2Icon,
  CogIcon,
  KeyIcon,
  UserGroupIcon,
  GlobeAmericasIcon,
  BuildingStorefrontIcon
} from "@heroicons/vue/24/outline";
import {
  BuildingLibraryIcon as BuildingLibraryIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  CogIcon as CogIconSolid,
  KeyIcon as KeyIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  GlobeAmericasIcon as GlobeAmericasIconSolid,
  BuildingStorefrontIcon as BuildingStorefrontIconSolid,
} from "@heroicons/vue/24/solid";
import {computed, provide} from "vue";
import {useAccountStore} from "../stores/AccountStore";
import {ENTERPRISE_OWNER} from "../constants/Roles";
import {getEnterpriseNouns} from "../helpers/EnterpriseNouns";
import {ENTERPRISE_TYPE_CORPORATE_CHAIN, ENTERPRISE_TYPE_STAFFING_AGENCY} from "../constants/EnterpriseTypes";

const accountStore = useAccountStore();

const activeEnterpriseType = computed(() => accountStore.user?.activeEnterprise?.type);
const activeEnterpriseNouns = computed(() => getEnterpriseNouns(activeEnterpriseType.value));

provide("activeEnterpriseNouns", activeEnterpriseNouns);

const menuItems = computed(() => {
  let enterpriseGroupIcon;

  switch (activeEnterpriseType.value) {
  case ENTERPRISE_TYPE_CORPORATE_CHAIN:
    enterpriseGroupIcon = {
      icon: GlobeAmericasIcon,
      iconSolid: GlobeAmericasIconSolid,
    };
    break;
  case ENTERPRISE_TYPE_STAFFING_AGENCY:
    enterpriseGroupIcon = {
      icon: UserGroupIcon,
      iconSolid: UserGroupIconSolid,
    };
    break;
  default:
    enterpriseGroupIcon = {
      icon: BuildingStorefrontIcon,
      iconSolid: BuildingStorefrontIconSolid,
    };
  }

  const baseItems = [
    {
      name: activeEnterpriseNouns.value.groupPlural,
      link: "/enterprise-groups",
      icon: enterpriseGroupIcon.icon,
      iconSolid: enterpriseGroupIcon.iconSolid,
    },
    {
      name: activeEnterpriseNouns.value.companyNamePlural,
      link: "/enterprise-companies",
      icon: BuildingOffice2Icon,
      iconSolid: BuildingOffice2IconSolid,
    },
    {
      name: "Users",
      link: "/enterprise-access-manager",
      icon: KeyIcon,
      iconSolid: KeyIconSolid,
    },
    {
      name: "Billing",
      link: "/enterprise-billing-groups",
      icon: BuildingLibraryIcon,
      iconSolid: BuildingLibraryIconSolid,
    },
  ];

  if (accountStore.user?.activeEnterprise?.role === ENTERPRISE_OWNER) {
    baseItems.push({
      name: "Settings",
      link: "/enterprise-settings",
      icon: CogIcon,
      iconSolid: CogIconSolid,
    });
  }

  return baseItems;
});

</script>
