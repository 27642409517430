<template>
  <nav id="desktopNav" :class="navClasses">
    <RouterLink :to="dashboardLink">
      <ApplicationLogo :class="applicationLogoClasses"/>
      <ApplicationIcon v-if="!isMenuOpen" class="my-2 hidden h-8 xl:mx-auto xl:my-7 xl:block"/>
    </RouterLink>
    <TransitionScale :disable-transition="windowWidth >= 1280">
      <div :key="isMenuOpen" :class="menuContainerClasses">
        <XMarkIcon v-if="isMenuOpen" @click="toggleMenu" class="absolute top-5 right-5 h-6 w-6 xl:hidden"/>
        <MenuItem v-for="item in menuItems" :key="item.link" v-bind="item" :is-open="isMenuOpen" :is-beta="item.beta || false"/>
      </div>
    </TransitionScale>
    <button v-if="!isMenuOpen" @click="toggleMenu" type="button" id="mobileMenuToggle">
      <Bars2Icon
        class="-mr-3 h-12 w-12 p-3 transition-all text-menuText xl:hidden"/>
    </button>
    <div @click="toggleMenu"
         class="fixed bottom-3 left-0 hidden cursor-pointer items-center gap-4 px-8 pt-6 opacity-60 text-menuText hover:underline hover:opacity-100 xl:flex"
    :class="isMenuOpen ? 'flex-row pb-6' : 'flex-col pb-8'">
      <ChevronDoubleLeftIcon class="h-4 w-4 transition-all" :class="isMenuOpen ? 'rotate-0' : '-rotate-180'"/>
      <span :class="!isMenuOpen && 'hidden'" class="whitespace-nowrap">Collapse Menu</span>
      <span :class="isMenuOpen && 'hidden'" class="absolute bottom-0.5 whitespace-nowrap text-sm">Expand</span>
    </div>
  </nav>
</template>

<script setup>
import ApplicationLogo from "./ApplicationLogo.vue";
import ApplicationIcon from "./ApplicationIcon.vue";
import MenuItem from "./MenuItem.vue";
import TransitionScale from "./TransitionScale.vue";
import {Bars2Icon, ChevronDoubleLeftIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import {computed} from "vue";

const props = defineProps({
  isMenuOpen: Boolean,
  dashboardLink: String,
  menuItems: Array,
  windowWidth: Number,
});

const emits = defineEmits(["update:isMenuOpen"]);

const applicationLogoClasses = computed(() => {
  return [
    "my-1 h-8 xl:mx-auto xl:my-7 xl:h-9",
    !props.isMenuOpen && "xl:hidden"
  ];
});

const navClasses = computed(() => {
  return [
    "flex w-full items-center justify-between px-4 transition-all duration-300 bg-menu hide-scrollbar xl:h-full xl:flex-col xl:justify-start xl:px-0",
    props.isMenuOpen ? "xl:w-56 2xl:w-60 xl:z-10" : "xl:w-20 xl:z-10",
  ];
});

const menuContainerClasses = computed(() => {
  return [
    "absolute flex w-full flex-wrap items-center justify-center gap-2 overflow-hidden py-2 text-menuText hide-scrollbar xl:relative xl:flex xl:flex-col xl:flex-nowrap xl:justify-start",
    props.isMenuOpen ? "top-0 left-0 h-screen overflow-y-auto xl:h-auto right-0 z-50 py-24 xl:py-0 gap-4 xl:gap-2 bg-menu px-2.5" : "hidden xl:flex xl:relative px-2"
  ];
});

const toggleMenu = () => emits("update:isMenuOpen", !props.isMenuOpen);
</script>
