export const displayResponseMessage = (res) => {
  if (Object.prototype.hasOwnProperty.call(res, "message")) {
    switch (typeof res?.message) {
    case "string":
      if (res.message) toast.success(res.message);
      break;
    case "object":
      for (let key of Object.keys(res.message)) {
        for (let val of res.message[key]) {
          if (val) toast.success(val);
        }
      }
      break;
    }
  }

  if (Object.prototype.hasOwnProperty.call(res, "warning")) {
    switch (typeof res?.warning) {
    case "string":
      if (res.warning) toast.warning(res.warning);
      return;
    case "object":
      for (let key of Object.keys(res.warning)) {
        for (let val of res.warning[key]) {
          if (val) toast.warning(val);
        }
      }
      return;
    }
  }

  if (Object.prototype.hasOwnProperty.call(res, "error")) {
    switch (typeof res?.error) {
    case "string":
      if (res.error) toast.error(res.error);
      break;
    case "object":
      for (let key of Object.keys(res.error)) {
        for (let val of res.error[key]) {
          if (val) toast.error(val);
        }
      }
      break;
    }
  }
};
