import BaseApi from "./BaseApi";
import axios from "axios";

class EnterpriseApi extends BaseApi {
  constructor() {
    super("enterprises");
  }

  setEnterprise = (id) => axios.put(`${this.baseUrl}/set/${id}`);

  upgradeCompanyToEnterprise = (companyId, data) => axios.post(`${this.baseUrl}/upgrade/${companyId}`, data);
}

export default EnterpriseApi;
