<template>
  <div>
    <!-- Desktop & Hamburger Menu -->
    <MenuSideNavigationDesktop v-model:is-menu-open="isMenuOpen" @update:is-menu-open="setMenuState"
                               :menu-items="getMenuItems"
                               :dashboard-link="dashboardLink" :window-width="windowWidth"/>
    <!-- Bottom Mobile Menu Only -->
    <MenuSideNavigationMobile v-model:is-menu-open="isMenuOpen" @update:is-menu-open="setMenuState"
                              :menu-items="menuItems"
                              :window-width="windowWidth"/>
  </div>
</template>

<script setup>
import MenuSideNavigationDesktop from "./MenuSideNavigationDesktop.vue";
import MenuSideNavigationMobile from "./MenuSideNavigationMobile.vue";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  BellIcon,
  Cog8ToothIcon,
  LinkIcon,
  UserCircleIcon,
} from "@heroicons/vue/24/outline/index.js";
import {usePageMetaStore} from "../stores/PageMetaStore.js";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {BellAlertIcon} from "@heroicons/vue/24/solid/index.js";
import {useAccountStore} from "../stores/AccountStore.js";
import {useRouter} from "vue-router";

const TAILWIND_XL_BREAKPOINT = 1280;

const props = defineProps({
  dashboardLink: String,
  menuItems: Array,
  accountLink: String,
  settingsLink: String,
  notificationLink: String,
});

const pageMetaStore = usePageMetaStore();
const accountStore = useAccountStore();
const windowWidth = ref(window.innerWidth);
const isMenuOpen = ref(false);
const router = useRouter();

const {user} = accountStore;

const accountMenuItems = computed(() => [
  ...(user?.companies ? [{name: "Switch Accounts", link: "/launch-pad", icon: ArrowsRightLeftIcon}] : []),
  ...(user?.activeCompany ? [{name: "Integrations", link: "/integrations", icon: LinkIcon}] : []),
  {name: "Account", link: props.accountLink, icon: UserCircleIcon},
  {name: "Logout", link: "/logout", icon: ArrowLeftOnRectangleIcon},
]);

const mobileMenuItems = [
  ...(props.notificationLink ? [{
    name: "Notifications",
    link: props.notificationLink,
    icon: BellIcon,
    iconSolid: BellAlertIcon,
    isNotificationIcon: true
  }] : []),
  ...(props.settingsLink ? [{name: "Settings", link: props.settingsLink, icon: Cog8ToothIcon}] : []),
];

const getMenuItems = computed(() => [
  ...props.menuItems,
  ...(windowWidth.value < TAILWIND_XL_BREAKPOINT ? [...mobileMenuItems, ...accountMenuItems.value] : []),
]);

const setStyles = () => pageMetaStore.menuLeftWidth = windowWidth.value >= TAILWIND_XL_BREAKPOINT ? isMenuOpen.value ? "240px" : "80px" : "0";

if (windowWidth.value >= TAILWIND_XL_BREAKPOINT) {
  isMenuOpen.value = localStorage.getItem("menuState") !== "closed";
  setStyles();
  localStorage.setItem("menuState", isMenuOpen.value ? "open" : "closed");
}

const setMenuState = isOpen => {
  isMenuOpen.value = isOpen;
  setStyles();
  if (windowWidth.value >= TAILWIND_XL_BREAKPOINT) localStorage.setItem("menuState", isOpen ? "open" : "closed");
};

const handleWindowWidth = () => {
  windowWidth.value = window.innerWidth;
  setMenuState(windowWidth.value >= TAILWIND_XL_BREAKPOINT && localStorage.getItem("menuState") !== "closed");
};

onMounted(() => {
  window.addEventListener("resize", handleWindowWidth);
  router.afterEach(() => windowWidth.value < TAILWIND_XL_BREAKPOINT && setMenuState(false));
});

onBeforeUnmount(() => window.removeEventListener("resize", handleWindowWidth));
</script>
