<template>
  <TransitionFade>
    <div
      :key="showLauncher"
      :class="launcherClasses.container"
      class="w-12 h-12 min-w-12 min-h-12 max-h-12 max-w-12 relative">
      <button
        id="intercom-launcher"
        type="button"
        aria-label="Open Intercom Messenger"
        aria-live="polite"
        :class="launcherClasses.button"
        class="bg-bdBlue-600 focus:ring ring-bdBlue-200 ring-offset-2 hover:bg-bdBlue-700 flex items-center justify-center text-white absolute inset-0 z-max intercom-transition cursor-pointer rounded-full shadow-lg transition-all focus:outline-none"
      >
        <svg
          class="h-6 -translate-x-[0.08rem]"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32" fill="currentColor">
          <path
            d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
        </svg>
      </button>
    </div>
  </TransitionFade>
</template>

<script setup>
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import TransitionFade from "./TransitionFade.vue";

const router = useRouter();

const isMouseDown = ref(false);
const showLauncher = ref(!!router.currentRoute.value.meta?.intercom);

router.afterEach((to) => showLauncher.value = !!to?.meta?.intercom);

const launcherClasses = computed(() => {
  return {
    button: {
      "scale-75": isMouseDown.value,
    },
    container: {
      "hidden": !showLauncher.value,
    },
  };
});
</script>
