import BaseApi from "./BaseApi";
import axios from "axios";

class NotificationApi extends BaseApi {
  constructor() {
    super("notifications");
  }
  unreadCount = () => axios.get(`${this.baseUrl}/unread-count/`);

  persistentNotifications = () => axios.get("/api/persistent-notifications/");
}

export default NotificationApi;
