<template>
  <TransitionFadeGroup>

    <MenuBanner v-for="banner in banners"
                :key="banner.key"
                :variant="banner.variant"
                :content="banner.content"
                @dismiss="dismiss(banner)">

      <template v-if="banner.key === UNPAID_INVOICE_KEY">
        We were unable to process payment on an invoice. Please
        <AuthRoleGate :roles="[COMPANY_ADMIN]">
          <template #default>
            <RouterLink to="/company-billing/edit" class="cursor-pointer underline">update your payment information</RouterLink>
          </template>
          <template #blocked>
            contact a company admin
          </template>
        </AuthRoleGate>
        by {{banner.accountWillLockAt}} or you will not be able to access your account.
      </template>

      <template v-else-if="banner.key === TRIAL_ENDS_KEY">
        Thanks for joining Business Draft! You have {{banner.timeRemaining}} remaining on your trial.
      </template>

      <template v-else-if="banner.key === CANCELLED_KEY">
        {{banner.copy}}
      </template>

      <template v-else-if="banner.key === DEMO_EXPIRES_KEY">
        <a href="/api/demo-accounts/trial-link" target="_blank"
           class="hover:underline">
          {{banner.copy}}
        </a>
      </template>

    </MenuBanner>

  </TransitionFadeGroup>
</template>

<script setup>
import AuthRoleGate from "./AuthRoleGate.vue";
import MenuBanner from "./MenuBanner.vue";
import TransitionFadeGroup from "./TransitionFadeGroup.vue";
import {useAccountStore} from "../stores/AccountStore.js";
import {computed, ref, watch} from "vue";
import {formatDateLong} from "../helpers/FormatDates.js";
import {COMPANY_ADMIN} from "../constants/Roles.js";
import {GRACE_PERIOD_IN_DAYS_FOR_PAYING_CUSTOMER_WITH_CARD_DECLINED} from "../constants/Billing.js";
import {ENV_DEMO} from "../constants/EnvironmentType";
import pluralize from "pluralize-esm";
import {useDemoTimer} from "../composables/DemoTimer";

const accountStore = useAccountStore();

const now = new Date();

const isDemo = import.meta.env.VITE_ENV === ENV_DEMO;

const dismissedBanners = ref([]);

const TRIAL_ENDS_KEY = "trial-ends";
const UNPAID_INVOICE_KEY = "unpaid-invoice";
const CANCELLED_KEY = "cancelled";
const DEMO_EXPIRES_KEY = "demo";

const {
  demoMinutesRemaining,
  startDemoInterval,
  cancelDemoInterval,
} = useDemoTimer(accountStore);

const banners = computed(() => {
  let items = [];

  // Banner #1: If the trial is ending in the future, show how many days are left.
  const trialEnds = accountStore.user?.activeCompany?.billable_entity_trial_ends_at;
  const trialEndsDate = trialEnds ? new Date(trialEnds) : null;

  if (trialEndsDate && !dismissedBanners.value.includes(TRIAL_ENDS_KEY) && now < trialEndsDate) {
    const daysRemaining = Math.floor((trialEndsDate - now) / (1000 * 60 * 60 * 24));
    let timeRemaining = daysRemaining === 1 ? "1 day" : `${daysRemaining} days`;
    if (daysRemaining < 1) timeRemaining = "less than 1 day";

    items.push({
      key: TRIAL_ENDS_KEY,
      variant: "success",
      timeRemaining: timeRemaining,
    });
  }

  // Banner #2: If there are any unpaid invoices, show an error banner.
  const firstUnpaidInvoice = accountStore.user?.activeCompany?.first_unpaid_invoice_at;
  const firstUnpaidInvoiceDate = firstUnpaidInvoice ? new Date(firstUnpaidInvoice) : null;

  if (firstUnpaidInvoiceDate && !dismissedBanners.value.includes(UNPAID_INVOICE_KEY)){
    const accountWillLockAt = new Date(firstUnpaidInvoiceDate);
    accountWillLockAt.setDate(accountWillLockAt.getDate() + GRACE_PERIOD_IN_DAYS_FOR_PAYING_CUSTOMER_WITH_CARD_DECLINED);

    items.push({
      key: UNPAID_INVOICE_KEY,
      variant: "error",
      accountWillLockAt: formatDateLong(accountWillLockAt),
    });
  }

  // Banner #3: If the company has canceled, show a banner.
  const cancelledAt = accountStore.user?.activeCompany?.cancellation_at;
  const cancelledAtDate = cancelledAt ? new Date(cancelledAt) : null;

  if (cancelledAtDate && !dismissedBanners.value.includes(CANCELLED_KEY)) {
    cancelledAtDate.setSeconds(cancelledAtDate.getSeconds() + 30);

    const isOnTrial = trialEndsDate && now < trialEndsDate;

    items.push({
      key: CANCELLED_KEY,
      variant: "error",
      copy: isOnTrial ?
        `Your account will close ${formatDateLong(cancelledAtDate)} when your trial ends. Decide to stay? Contact support, we'd love to keep you!` :
        `Your subscription ends on ${formatDateLong(cancelledAtDate)}. Please note, any charges for usage until that date will still apply.`,
    });
  }

  // Banner #4: If it's a demo, show time remaining.
  if (isDemo && !dismissedBanners.value.includes(DEMO_EXPIRES_KEY)) {

    let copy = `You have ${demoMinutesRemaining.value} ${pluralize("minute", demoMinutesRemaining.value)} remaining on your demo.`;
    const lessThanFiveMinutes = demoMinutesRemaining.value <= 5;

    if (lessThanFiveMinutes) copy = "Under 5 Minutes Remaining – Switch to 14-Day Free Trial 🎉";

    items.push({
      key: DEMO_EXPIRES_KEY,
      variant: "success",
      copy,
    });
  }

  return items;
});

const dismiss = (banner) => {
  if (banner.key === DEMO_EXPIRES_KEY) cancelDemoInterval();
  dismissedBanners.value.push(banner.key);
};

watch(() => accountStore.user?.id, (newId, oldId) => {
  if (newId === oldId) return;
  if (newId && !oldId) startDemoInterval();
  if (oldId && !newId) cancelDemoInterval();
}, {immediate: true});
</script>
