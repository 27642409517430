<template>
  <div
    class="relative flex items-center justify-center overflow-hidden rounded-full"
    :class="containerClasses"
    :style="containerStyles"
  >
    <img
      v-if="avatar"
      :src="avatar"
      alt="Profile Picture"
      class="h-full min-h-full w-full min-w-full object-cover"
      loading="lazy"
    />
    <p
      v-else
      class="select-none overflow-hidden text-center hide-scrollbar"
      :class="textClass"
      :style="colorStyle"
    >
      {{ initials }}
    </p>
  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import {twMerge} from "tailwind-merge";

const props = defineProps({
  avatar: String,
  name: String,
  size: {
    type: String,
    validator(value) {
      return ["xxs", "xs", "sm", "md", "base", "lg", "xl"].includes(value);
    },
  },
  bgClass: {
    type: String,
    default: "bg-slate-200",
  },
  colorClass: {
    type: String,
    default: "text-slate-400",
  },
  bgStyles: {
    type: String,
    default: "",
  },
  colorStyle: {
    type: String,
    default: "",
  },
});

const initials = ref("");

const nameToInitials = () => {
  if (!props.name) return initials.value = "";

  let cleanName = props.name.replace(/undefined/g, "");
  if (!cleanName || cleanName === " ") return initials.value = "";

  let tempInitials = props.name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "");

  initials.value = tempInitials.substring(0, 3);  // Max 3 initials
};

nameToInitials();

watch(() => props.name, nameToInitials);

const classBasedOnSize = computed(() => {
  switch (props.size) {
  case "xxs":
    return "h-6 w-6";
  case "xs":
    return "h-8 w-8";
  case "sm":
    return "h-10 w-10";
  case "md":
    return "bg-slate-200";
  case "base":
    return "bg-slate-200";
  case "lg":
    return "h-16 w-16";
  case "xl":
    return "h-20 w-20";
  default:
    return "";
  }
});

const styleBasedOnSize = computed(() => {
  switch (props.size) {
  case "xxs":
    return "height: 1.5rem;min-height: 1.5rem;width:1.5rem;min-width: 1.5rem;";
  case "xs":
    return "height: 2rem;min-height: 2rem;width:2rem;min-width: 2rem;";
  case "sm":
    return "height: 2.5rem;min-height: 2.5rem;width:2.5rem;min-width: 2.5rem;";
  case "md":
    return "height: 3rem;min-height: 3rem;width:3rem;min-width: 3rem;";
  case "base":
    return "height: 3.5rem;min-height: 3.5rem;width:3.5rem;min-width: 3.5rem;";
  case "lg":
    return "height: 4rem;min-height: 4rem;width:4rem;min-width: 4rem;";
  case "xl":
    return "height: 5rem;min-height: 5rem;width:5rem;min-width: 5rem;";
  default:
    return "";
  }
});

const textBasedOnSize = computed(() => {
  switch (props.size) {
  case "xxs":
    return "text-xxs -mb-0.5";
  case "xs":
    return "text-xs";
  case "sm":
    return "text-base";
  case "md":
    return "text-lg";
  case "base":
    return "text-lg";
  case "lg":
    return "text-xl";
  case "xl":
    return "text-2xl";
  default:
    return "text-base";
  }
});

const containerClasses = computed(() => {
  return twMerge(classBasedOnSize.value + "", props.bgClass);
});

const containerStyles = computed(() => {
  return [styleBasedOnSize.value, props.avatar ? "background:transparent;" : "", props.bgStyles];
});

const textClass = computed(() => {
  return [textBasedOnSize.value + "", props.colorClass];
});
</script>
