// Setup Vue Toast
import {useToast} from "vue-toastification";
// Setup Axios
import axios from "axios";
import InfiniteLoadingProgressBar from "./helpers/InfiniteLoadingProgressBar.js";
// Setup Pusher
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {displayResponseMessage} from "./helpers/AxiosToast.js";

const toast = useToast();
window.toast = toast;

// Setup Nanobar
window.progressBar = new InfiniteLoadingProgressBar();

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Enable Laravel Sanctum
window.axios.defaults.withCredentials = true;

const LOGIN_URL = "/login";
const LAUNCH_PAD_URL = "/launch-pad";

// Setup Pusher
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  wsHost: import.meta.env.VITE_PUSHER_HOST,
  wsPort: import.meta.env.VITE_PUSHER_PORT,
  wssPort: import.meta.env.VITE_PUSHER_PORT,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: import.meta.env.VITE_PUSHER_SCHEME === "https",
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
});

// Axios interceptor
window.axios.interceptors.response.use(
  (response) => {
    if (response.status) {
      switch (response.status) {
      case 200:
      case 201:
        return Promise.resolve(response.data);
      default:
        return Promise.resolve(response.data);
      }
    }
  },
  (error) => {
    if (error?.response?.status) {
      switch (error.response.status) {
      case 401:
        window.location.href = LOGIN_URL;
        window.localStorage.clear();
        document.cookie = "token=; Max-Age=-99999999;";
        break;
      case 403:
        if (error?.response?.data?.message) handle403(error?.response?.data?.message);
        else window.location.href = LAUNCH_PAD_URL;
        break;
      case 404:
        break;
      default:
        let res = {};
        console.error("error",  error?.response?.data?.error ||
          error?.response?.data?.errors ||
          error?.response?.statusText ||
          error?.response?.data?.message);

        res.error =
          error?.response?.data?.error ||
          error?.response?.data?.errors ||
          error?.response?.statusText ||
          error?.response?.data?.message;
        res.warning =
          error?.response?.data?.warning ||
          error?.response?.data?.warnings;

        displayResponseMessage(res);
        break;
      }
    }

    console.error(error);

    return Promise.reject(error.response);
  }
);

const handle403 = (errorCode) => {
  switch (errorCode) {
  case "interviews.not_participant":
    toast.warning("Only interview participants, hiring managers, and company admins can access this page.");
    goBack();
    break;
  default:
    window.location.href = LAUNCH_PAD_URL;
  }
};

/**
 * Go back while ensuring that the referrer is from the same domain
 * @param fallbackUrl
 */
const goBack = (fallbackUrl = LAUNCH_PAD_URL) => {
  const referrer = document.referrer;
  const currentDomain = window.location.hostname;

  if (window.history.length > 1) {
    if (referrer.includes(currentDomain)) window.history.go(-1);
    else window.location.href = fallbackUrl;
  } else {
    window.location.href = fallbackUrl;
  }
};
