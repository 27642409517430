export default class BaseApi {
  baseUrl = "";

  constructor(url) {
    this.baseUrl = "/api/" + url;
  }

  index(params = null) {
    return axios.get(this.baseUrl, { params });
  }

  show(id, params = null) {
    return axios.get(`${this.baseUrl}/${id}`, { params });
  }

  create(data) {
    return axios.post(this.baseUrl, data);
  }

  createWithFiles(formData) {
    return axios.post(`${this.baseUrl}`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  }

  update(id, data) {
    return axios.put(`${this.baseUrl}/${id}`, data);
  }

  updateWithFiles(id, formData) {
    const method = formData.get("_method") || "PUT";
    return axios.post(`${this.baseUrl}/${id}?_method=${method}`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  }

  destroy(id, params = null) {
    return axios.delete(`${this.baseUrl}/${id}`, { params });
  }
}
