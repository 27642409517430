import Nanobar from "nanobar";
import {nanobarOptions} from "../config/nanobarOptions.js";

window.nanobar = new Nanobar(nanobarOptions);


class InfiniteLoadingProgressBar {
  constructor() {
    this.interval = null;
    this.progress = 0;
    this.maxProgressBeforeComplete = 99;
  }

  _expProgress() {
    const factor = 0.05 + Math.exp(4 * (this.progress / 100) - 2);
    this.progress += 2 / factor;
    if (this.progress > this.maxProgressBeforeComplete) {
      this.progress = this.maxProgressBeforeComplete;
    }

    return this.progress;
  }

  start() {
    if (!this || this.interval) return;

    this.progress = 0;
    this.interval = setInterval(() => {
      const progress = this._expProgress();
      window.nanobar.go(progress);
    }, 100);
  }

  complete() {
    if (!this) return;
    if (this.progress && this.progress !== 0) {
      window.nanobar.go(100);
      this.progress = 0;
    }

    if (this && this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}

export default InfiniteLoadingProgressBar;
