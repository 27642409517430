<template>
  <OnClickOutside
    :class="!settingsLink && !notificationLink && 'ml-auto'"
    @trigger="() => updateOpen(false)">

    <button type="button"
            @click="() => updateOpen(!props.open)"
            class="-mr-2 ml-3 hidden md:block">

      <UtilityProfileInitials
        size="md"
        :name="accountStore.user?.activeCompany?.name"
        :avatar="accountStore.user?.activeCompany?.avatar"
        class="cursor-pointer"/>

    </button>

    <TransitionDropdown>

      <div
        v-if="open"
        class="absolute top-16 right-20 z-50 mr-2 hidden rounded bg-white px-2 text-slate-500 shadow md:block">
        <RouterLink
          to="/launch-pad"
          class="flex items-center justify-start gap-3 px-4 py-3.5 hover:text-slate-900">
          <ArrowsRightLeftIcon
            class="h-4 w-4"/>
          Switch Accounts
        </RouterLink>
      </div>

    </TransitionDropdown>

  </OnClickOutside>
</template>

<script setup>
import TransitionDropdown from "./TransitionDropdown.vue";
import UtilityProfileInitials from "./UtilityProfileInitials.vue";
import {OnClickOutside} from "@vueuse/components";
import {ArrowsRightLeftIcon} from "@heroicons/vue/24/outline/index.js";
import {useAccountStore} from "../stores/AccountStore";

const emits = defineEmits(["update:open"]);
const props = defineProps({
  open: Boolean,
  settingsLink: String,
  notificationLink: String,
});

const accountStore = useAccountStore();

const updateOpen = (value) => emits("update:open", value);
</script>
