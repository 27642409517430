<template>
  <footer :class="backgroundClass"
          class="absolute bottom-0 left-0 flex h-20 w-full items-center gap-6 px-6 text-xs sm:text-sm md:px-12"
  >
    <a href="/" target="_blank" class="hover:underline">© Business Draft, Inc. All rights reserved.</a>
    <a href="/terms-of-service" target="_blank" class="ml-auto hover:underline"
    >Terms of Service</a
    >
    <a href="/privacy-policy" target="_blank" class="hover:underline"
    >Privacy Policy</a
    >
  </footer>
</template>

<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router";

const props = defineProps({
  isDark: Boolean,
});

const route = useRoute();

const backgroundClass = computed(() => {
  if (route.meta?.requiresAuth) return "opacity-0";
  return props.isDark ? "bg-slate-900 text-white" : "bg-slate-100 text-slate-300";
});
</script>
