<template>
  <OnClickOutside
    :class="!notificationLink && 'ml-auto'"
    @trigger="() => updateOpen(false)">

    <button
      type="button"
      aria-label="Toggle Show Settings Menu"
      class="relative hidden cursor-pointer p-4 md:block">

      <Cog8ToothIconSolid
        v-if="open"
        class="h-5 w-5"
        @click="() => updateOpen(!open)"
      />

      <Cog8ToothIcon
        v-else
        class="h-5 w-5"
        @click="() => updateOpen(!open)"
      />

      <TransitionDropdown>
        <span
          v-if="open"
          class="absolute top-14 right-0 z-50 mr-2 hidden rounded bg-white px-6 text-slate-500 shadow mt-0.5 md:block">

          <template
            v-for="(item, index) in settingsMenuItems"
            :key="item.link">
            <RouterLink
              v-if="item.link"
              :to="item.link"
              class="flex items-center justify-start gap-3 whitespace-nowrap py-3.5 hover:text-slate-900">

              <Component
                :is="item.icon"
                class="h-4 w-4"
              />
              {{ item.name }}

            </RouterLink>

            <span
              v-if="index !== accountMenuItemsCount - 1"
              class="h-px w-full bg-slate-100"
            />

          </template>

        </span>
      </TransitionDropdown>

    </button>

  </OnClickOutside>
</template>
<script setup>
import {OnClickOutside} from "@vueuse/components";
import {Cog8ToothIcon as Cog8ToothIconSolid} from "@heroicons/vue/24/solid/index.js";
import {Cog8ToothIcon} from "@heroicons/vue/24/outline/index.js";
import {computed} from "vue";
import {BellIcon} from "@heroicons/vue/24/outline/index";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import TransitionDropdown from "./TransitionDropdown.vue";

const emits = defineEmits(["update:open"]);
const props = defineProps({
  accountLink: String,
  settingsLink: String,
  notificationLink: String,
  accountMenuItemsCount: Number,
  open: Boolean,
});

const updateOpen = (value) => emits("update:open", value);

const settingsMenuItems = computed(() => [
  {
    name: "Notification Settings",
    link: props.settingsLink,
    icon: BellIcon,
  },
  {
    name: "Account Settings",
    link: props.accountLink,
    icon: UserCircleIcon,
  }
]);
</script>
