import {ref} from "vue";

export const useDemoTimer = (accountStore) => {
  const demoMinutesRemaining = ref(60); // Start with 60 minutes
  let demoInterval;

  const handleDemoIntervalTick = () => {
    const userCreatedAt = accountStore.user?.created_at;
    if (!userCreatedAt) return;

    const userCreatedAtDate = new Date(accountStore.user?.created_at);
    const now = new Date();

    const minutesAgo = Math.floor((now - userCreatedAtDate) / (1000 * 60));

    if (minutesAgo >= 60) {
      demoMinutesRemaining.value = 0;
      clearInterval(demoInterval);
      return;
    }

    demoMinutesRemaining.value = 60 - minutesAgo;
  };

  const startDemoInterval = () => {
    handleDemoIntervalTick();
    setInterval(handleDemoIntervalTick, 15 * 1000); // Refresh every 15 seconds
  };

  const cancelDemoInterval = () => {
    clearInterval(demoInterval);
  };

  return {
    demoMinutesRemaining,
    startDemoInterval,
    cancelDemoInterval,
  };
};
