import {computed, defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, provide, ref, watch} from "vue";
import {useAccountStore} from "../stores/AccountStore";
import {LOCATION_IN_PERSON, LOCATION_PHONE, LOCATION_WEB_CONFERENCE} from "../constants/Interviews";
import {MapIcon, PhoneIcon, VideoCameraIcon, ChatBubbleLeftRightIcon, TrophyIcon} from "@heroicons/vue/20/solid/index.js";
import NotificationApi from "../api/NotificationApi";

export const usePersistentNotifications = (router) => {
  if (!window._persistentNotificationsInstance) {
    window._persistentNotificationsInstance = (() => {

      const disabledOnRouteNames = [
        "candidate-message-index",
        "company-message-index",
        "company-interview-show",
        "candidate-interview-show",
      ];

      const accountStore = useAccountStore();

      const toastQueue = ref([]);
      const currentTime = ref(new Date().getTime());
      const showPersistentNotifications = ref(false);
      const isOnDisabledRoute = computed(() => disabledOnRouteNames.includes(router.currentRoute.value.name));

      let updateCurrentTimeInterval = null;

      const togglePersistentNotifications = () => showPersistentNotifications.value = !showPersistentNotifications.value;

      const sonnerComponent = defineAsyncComponent(() => import("../components/SonnerToasts.vue"));

      let lastAddedToastAt = null;
      const minTimeBetweenToasts = 2000;

      const addToastToQueue = ({
        id = Math.random().toString(36).substring(9),
        title,
        description,
        avatar,
        avatarAlt,
        icon,
        iconAria,
        iconClasses,
        primaryActionText,
        primaryActionButtonColor,
        primaryActionLink,
        primaryActionCallback,
        secondaryActionText = null,
        secondaryActionButtonColor = null,
        secondaryActionLink = null,
        secondaryActionCallback = null,
        conversationUuid = null,
        conversationMessageId = null,
        conversationSenderFirst = null,
        actionIsMessage = false,
        validFrom = null,
        validTo = null,
        dismissible = true,
        setCompanyId = null,
      }) => {
        if (toastQueue.value.find(t => t.id === id)) return;

        if (!validFrom || !validTo) dismissible = true;

        const timeToWait = Math.max(lastAddedToastAt ? minTimeBetweenToasts - (new Date().getTime() - lastAddedToastAt) : 0, 0);
        lastAddedToastAt = new Date().getTime() + timeToWait;

        setTimeout(() => {
          toastQueue.value.push({
            id,
            title,
            description,
            avatar,
            avatarAlt,
            icon,
            iconAria,
            iconClasses,
            primaryActionText,
            primaryActionButtonColor,
            primaryActionLink,
            primaryActionCallback,
            secondaryActionText,
            secondaryActionButtonColor,
            secondaryActionLink,
            secondaryActionCallback,
            validFrom,
            validTo,
            conversationUuid,
            conversationMessageId,
            conversationSenderFirst,
            actionIsMessage,
            dismissible,
            setCompanyId,
          });

          currentTime.value = new Date().getTime();
        }, timeToWait);
      };


      const formatRawNotification = (notification) => {
        const {type, metadata} = notification;

        const authUserId = accountStore.user?.id;

        if (type === "interview") {
          const {id, company, interviewee, location, duration_minutes, start_time, uuid} = metadata;
          let locationReadable, ctaText, icon, iconAria;

          const isInterviewee = interviewee?.id === authUserId;

          const howLongBeforeAfterEventToShow = isInterviewee ? 10 * 60 * 1000 : 5 * 60 * 1000; // 10 vs 5 min
          const validFrom = new Date(start_time).getTime() - howLongBeforeAfterEventToShow;
          const validTo = new Date(start_time).getTime() + howLongBeforeAfterEventToShow;

          switch (location) {
          case LOCATION_IN_PERSON:
            locationReadable = "In-Person Interview";
            icon = MapIcon;
            iconAria = "Map Icon";
            if (isInterviewee) ctaText = "Get Directions";
            else ctaText = "Interview Notes";
            break;
          case LOCATION_WEB_CONFERENCE:
            locationReadable = "Video Interview";
            ctaText = "Join Interview";
            icon = VideoCameraIcon;
            iconAria = "Video Camera Icon";
            break;
          case LOCATION_PHONE:
            locationReadable = "Phone Interview";
            ctaText = "Interview Notes";
            icon = PhoneIcon;
            iconAria = "Phone Icon";
            break;
          }
          const startTimeTimeOfDayOnly = new Date(start_time).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
          });

          const otherPartyName = isInterviewee ? company?.name : ((interviewee.first_name + " " + interviewee.last_name) + (company.nickname ? " • " + company.nickname : ""));

          addToastToQueue({
            id: "interview-" + id,
            title: `${duration_minutes}-min ${locationReadable}`,
            description: `Today at ${startTimeTimeOfDayOnly} • ${otherPartyName}`,
            avatar: isInterviewee ? company.avatar : interviewee.avatar,
            avatarAlt: isInterviewee ? company.name + " Logo" : (interviewee.first_name + " " + interviewee.last_name) + " Profile Picture",
            icon,
            iconAria,
            iconClasses: "bg-bdBlue-100 text-bdBlue-600",
            primaryActionText: ctaText,
            primaryActionButtonColor: "blue",
            primaryActionLink: isInterviewee ? `/interviews/${uuid}` : `/company-interviews/${uuid}`,
            secondaryActionText: "Details",
            secondaryActionButtonColor: "white-translucent",
            secondaryActionLink: isInterviewee ? `/interviews?interview=${id}` : `/company-interviews?interview=${id}`,
            secondaryActionCallback: () => {
              const event = new CustomEvent("open-interview-details", {detail: {id}});
              window.dispatchEvent(event);
            },
            validFrom,
            validTo,
            dismissible: false,
            setCompanyId: !isInterviewee ? company.id : null,
          });
          return;
        }

        if (type === "feature_announcement") {
          const {id, title, description, avatar, action_1_text, action_1_url, action_2_text, action_2_url} = metadata;

          addToastToQueue({
            id: "feature-announcement-" + id,
            title,
            description,
            avatar,
            avatarAlt: "Feature Announcement",
            icon: TrophyIcon,
            iconAria: "Trophy Icon",
            iconClasses: "bg-bdBlue-100 text-bdBlue-600",
            primaryActionText: action_1_text,
            primaryActionButtonColor: "blue",
            primaryActionLink: action_1_url,
            secondaryActionText: action_2_text,
            secondaryActionButtonColor: "white-translucent",
            secondaryActionLink: action_2_url,
            dismissible: true,
          });

          return;
        }

        console.error("Unknown notification type", type, metadata);
      };

      const convertMessageToNotification = (e) => {
        const authUserId = accountStore.user?.id;

        const {conversation, message} = e;

        if (authUserId === message.sender.id || message.type === "system") return;

        const activeCompanyIsConversationCompany = accountStore.user?.activeCompany?.id === conversation.company_id;
        const belongsToCompany = accountStore.users?.companies?.find(c => c.id === conversation.company_id);

        const senderOpportunityTitles = conversation?.conversation_users.find(cu => cu.user_id === message.sender.id)?.opportunity_titles || [];
        const firstOpportunityTitle = senderOpportunityTitles.length ? " • " + senderOpportunityTitles[0] : "";

        addToastToQueue({
          id: `message-${message.id}`,
          title: `${message.sender.first_name} ${message.sender.last_name}${!activeCompanyIsConversationCompany ? ` • ${conversation.company_name}` : firstOpportunityTitle}`,
          description: message.type === "text" ? message.content : `Sent a ${message.type} message`,
          avatar: message.sender.avatar || (!activeCompanyIsConversationCompany ? conversation.company_avatar : ""),
          avatarAlt: `${message.sender.first_name} ${message.sender.last_name} Profile Picture`,
          icon: ChatBubbleLeftRightIcon,
          iconAria: "Chat Bubble Icon",
          iconClasses: "bg-bdBlue-100 text-bdBlue-600",
          actionIsMessage: true,
          conversationUuid: conversation.uuid,
          conversationMessageId: message.id,
          conversationSenderFirst: message.sender.first_name,
          validFrom: new Date(message.created_at).getTime(),
          validTo: new Date(message.created_at).getTime() + (5 * 60 * 1000),
          dismissible: true,
          setCompanyId: belongsToCompany ? conversation.company_id : null,
        });
      };


      const getInitialPersistentNotifications = async () => {
        const api = new NotificationApi();

        try {
          const response = await api.persistentNotifications();
          for (const notification of response.data) {
            formatRawNotification(notification);
          }
        } catch (e) {
          console.error(e);
        }
      };

      const dismissToast = async (toastId) => {
        const toastIndex = toastQueue.value.findIndex(t => t.id === toastId);

        if (toastIndex >= 0) {
          toastQueue.value.splice(toastIndex, 1);
        }

        await nextTick();

        currentTime.value = new Date().getTime();
      };

      const currentToasts = computed(() => {
        if (!toastQueue.value || !toastQueue.value.length) return [];

        return toastQueue.value.filter(t => {
          if (!t?.validFrom || !t?.validTo) return true;
          return t.validFrom < currentTime.value && t.validTo > currentTime.value;
        });
      });

      const hasCurrentToasts = computed(() => !isOnDisabledRoute.value && currentToasts.value.length > 0);

      watch(() => accountStore.user?.id, async (newId, oldId) => {
        if (!newId || newId === oldId) return;

        toastQueue.value = [];
        await getInitialPersistentNotifications();
      }, {immediate: true});

      watch(() => currentToasts.value.length, (newLen, oldLen) => {
        if (!newLen || newLen === oldLen || newLen < oldLen) return;
        showPersistentNotifications.value = true;
      }, {immediate: true});

      watch(() => accountStore.hasUserChannel, (hasUserChannel) => {
        if (!hasUserChannel || !accountStore.userChannel) return;
        accountStore.userChannel.listen(".interview.starting.soon", formatRawNotification);
        accountStore.userChannel.listen(".message.created", convertMessageToNotification);
      }, {immediate: true});

      provide("dismissToast", dismissToast);

      onMounted(() => updateCurrentTimeInterval = setInterval(() => currentTime.value = new Date().getTime(), 20000));
      onBeforeUnmount(() => clearInterval(updateCurrentTimeInterval));

      return {
        currentToasts,
        hasCurrentToasts,
        showPersistentNotifications,
        sonnerComponent,
        togglePersistentNotifications,
        dismissToast,
      };
    })();
  }

  return window._persistentNotificationsInstance;
};
