import {useAccountStore} from "../stores/AccountStore.js";
import {getItem, setItem} from "./LocalStorage.js";
import {objectToCamelCase} from "./CaseTransformer.js";
import {ENV_DEMO} from "../constants/EnvironmentType";

export const redirectToDashboardIfAuthenticated = async (router) => {
  if (!await doubleCheckIsLoggedIn()) return;
  await sendToCorrectDashboard(router);
};

export const redirectOnLogin = async (router, userResource) => {
  if (!userResource?.id) {
    await router.push({path: "/two-factor-challenge"});
    return;
  }

  const accountStore = useAccountStore();
  accountStore.user = objectToCamelCase(userResource);
  await sendToCorrectDashboard(router);
};

export const sendToCorrectDashboard = async (router, sendToLaunchPad = true) => {
  const accountStore = useAccountStore();

  if (!sendToLaunchPad) toast.success(`Welcome back, ${accountStore.user?.firstName}!`);

  const hasCompanies = accountStore.user?.companies && accountStore.user?.companies.length > 0;
  const hasActiveCompany = accountStore.user?.activeCompany;

  const hasEnterprises = accountStore.user?.enterprises && accountStore.user?.enterprises.length > 0;
  const hasActiveEnterprise = accountStore.user?.activeEnterprise;

  const targetUrl = getItem("targetUrl");

  if (targetUrl) {

    const url = new URL(targetUrl, window.location.origin);
    url.searchParams.delete("set_company_id");
    url.searchParams.delete("set_enterprise_id");

    const relativeTargetPath = url.pathname + url.search;

    const resolved = router.resolve(relativeTargetPath);

    const isVueRouteButNoPermissions = resolved.matched.length && (
      (resolved.meta.requiresActiveEnterprise && !hasActiveEnterprise) ||
      (resolved.meta.requiresActiveCompany && !hasActiveCompany));

    if (resolved.matched.length && resolved.name !== "error-404" && url.pathname !== "/login" && !isVueRouteButNoPermissions) {
      setItem("targetUrl", "", 0);
      const queryParamsObject = Object.fromEntries(url.searchParams.entries());
      await router.push({path: relativeTargetPath, query: queryParamsObject});
      return;
    }

    const validHostnames = [
      "businessdraft.com",
      "businessdraftstaging.com",
      "localhost",
    ];

    if (!isVueRouteButNoPermissions && validHostnames.includes(url.hostname) && url.pathname !== "/login") {
      setItem("targetUrl", "", 0);
      window.location.href = url.href;
    }
  }

  setItem("hideQuickProfile", false, 0.5);

  if (hasEnterprises && !hasActiveEnterprise && sendToLaunchPad) {
    await router.push({path: "/launch-pad"});
    return;
  }

  if (hasCompanies && !hasActiveCompany && sendToLaunchPad) {
    await router.push({path: "/launch-pad"});
    return;
  }

  if (hasActiveEnterprise) {
    await router.push({path: "/enterprise-groups"});
    return;
  }

  if (hasActiveCompany) {
    await router.push({path: "/company-dashboard"});
    return;
  }

  await router.push({path: "/dashboard"});
};

export const redirectToLoginIfNotAuthenticated = async (router) => {
  let accountStore = useAccountStore();
  if (!accountStore.isLoggedIn) await accountStore.fetchUserAccount(); // Fetch the user account if not already fetched
  if (accountStore.isLoggedIn) return; // If the user is logged in, do nothing
  if (window.location.pathname === "/login") return; // If the user is on the login page, do nothing

  // Update the targetUrl in localStorage
  const targetUrl = window.location.pathname + window.location.search;
  setItem("targetUrl", targetUrl, 2);


  // try setting setCompanyId
  const queryParams = new URLSearchParams(window.location.search);
  const setCompanyId = queryParams.get("set_company_id");
  if (setCompanyId) {
    setItem("setCompanyId", setCompanyId, 2);
  }

  await router.push({path: "/login"});
};

export const doubleCheckIsLoggedIn = async () => {
  const accountStore = useAccountStore();
  if (!accountStore.isLoggedIn) await accountStore.fetchUserAccount(); // Fetch a user account if not already fetched
  if (!accountStore.isLoggedIn) return false; // If a user is not logged in
  return true;
};

export const isDemoRequired = (from, to) => {
  if (!to.meta.requiresActiveCompany || to.path === "/company-dashboard" || !from || from.path !== "/" || import.meta.env.VITE_ENV !== ENV_DEMO) return false;
  const accountStore = useAccountStore();
  if (!accountStore.isLoggedIn || !accountStore.user?.activeCompany) return false;
  return !accountStore.user.activeCompany?.demoCompleted;
};

export const checkUserHasRoles = (roles = []) => {
  const accountStore = useAccountStore();
  if (!accountStore.isLoggedIn) return false;
  return roles.includes(accountStore?.user?.activeCompany?.role || "");
};

export const hasActiveCompany = () => {
  let accountStore = useAccountStore();
  return !!accountStore.user?.activeCompany;
};

export const hasActiveEnterprise = () => {
  let accountStore = useAccountStore();
  return !!accountStore.user?.activeEnterprise;
};
