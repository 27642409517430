<template>
  <TransitionScale>
    <nav v-if="!pageMetaStore.forceHideMobileMenu && !isMenuOpen"
         class="fixed bottom-0 left-0 z-50 h-[3rem] w-full border-t border-gray-200 bg-white lg:hidden">
      <div class="mx-auto flex h-full max-w-lg font-medium gap-x-[3.1rem] items-center justify-center">
        <template v-for="item in menuItems" :key="item.name">
          <RouterLink :to="item.link" v-if="!item.link.includes('interview')"
                      class="inline-flex flex-col items-center justify-center group">
            <div class="flex items-center justify-center rounded-full transition-all">
              <Component :is="$route.fullPath.includes(item.link) ? item.iconSolid : item.icon"
                         class="h-[1.625rem] w-[1.625rem] transition-all"
                         :class="$route.fullPath.includes(item.link) ? 'text-slate-900' : 'text-slate-400'"/>
            </div>
          </RouterLink>
        </template>
      </div>
    </nav>
  </TransitionScale>
</template>

<script setup>
import TransitionScale from "./TransitionScale.vue";
import {usePageMetaStore} from "../stores/PageMetaStore.js";

defineProps({
  isMenuOpen: Boolean,
  menuItems: Array,
  windowWidth: Number,
});

const pageMetaStore = usePageMetaStore();
</script>
