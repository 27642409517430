<template>
  <MenuContainer
    is-company
    :menu-items="menuItems"
    dashboardLink="/company-dashboard"
    notificationLink="/company-notifications"
    settingsLink="/company-notification-preferences"
    accountLink="/company-account">
    <template #banner>
      <CompanyBanners/>
    </template>
    <template #default>
      <slot/>
    </template>
  </MenuContainer>
</template>

<script setup>
import MenuContainer from "../components/MenuContainer.vue";
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import {
  BriefcaseIcon as BriefcaseIconSolid,
  BuildingOfficeIcon as BuildingOfficeIconSolid,
  CalendarDaysIcon as CalendarDaysIconSolid,
  ChatBubbleOvalLeftEllipsisIcon as ChatBubbleOvalLeftEllipsisIconSolid,
  HomeIcon as HomeIconSolid,
  UsersIcon as UsersIconSolid,
} from "@heroicons/vue/24/solid";
import CompanyBanners from "../components/CompanyBanners.vue";

const menuItems = [
  {
    name: "Dashboard",
    link: "/company-dashboard",
    icon: HomeIcon,
    iconSolid: HomeIconSolid,
  },
  {
    name: "Opportunities",
    link: "/company-opportunities",
    icon: BriefcaseIcon,
    iconSolid: BriefcaseIconSolid,
  },
  {
    name: "Candidates",
    link: "/company-candidates",
    icon: UsersIcon,
    iconSolid: UsersIconSolid,
  },
  {
    name: "Interviews",
    link: "/company-interviews",
    icon: CalendarDaysIcon,
    iconSolid: CalendarDaysIconSolid,
  },
  {
    name: "Messages",
    link: "/company-messages",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    iconSolid: ChatBubbleOvalLeftEllipsisIconSolid,
  },
  {
    name: "Company",
    link: "/company-profile",
    icon: BuildingOfficeIcon,
    iconSolid: BuildingOfficeIconSolid,
  },
];
</script>
