import {BuildingStorefrontIcon, UserGroupIcon, BuildingOffice2Icon} from "@heroicons/vue/16/solid";

export const ENTERPRISE_TYPE_FRANCHISE = "franchise";
export const ENTERPRISE_TYPE_STAFFING_AGENCY = "staffing_agency";
export const ENTERPRISE_TYPE_CORPORATE_CHAIN = "corporate_chain";

export const enterpriseTypesWithIcons = [
  {
    iconIsComponent: true,
    icon: BuildingStorefrontIcon,
    iconClasses: "text-bdBlue-600 h-6 w-6 self-center min-w-[1.5]",
    title: "Franchise",
    description: "Independently owned locations operating under a common brand.",
    value: ENTERPRISE_TYPE_FRANCHISE,
  },
  {
    iconIsComponent: true,
    icon: UserGroupIcon,
    iconClasses: "text-bdYellow-600 h-6 w-6 self-center min-w-[1.5]",
    title: "Staffing Agency",
    description: "Organization managing multiple locations for workforce placement and hiring.",
    value: ENTERPRISE_TYPE_STAFFING_AGENCY,
  },
  {
    iconIsComponent: true,
    icon: BuildingOffice2Icon,
    iconClasses: "text-green-600 h-6 w-6 self-center min-w-[1.5]",
    title: "Corporation",
    description: "Company-owned and managed locations under centralized control.",
    value: ENTERPRISE_TYPE_CORPORATE_CHAIN,
  }
];
