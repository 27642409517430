import {defineStore} from "pinia";
import {ref} from "vue";

export const usePageMetaStore = defineStore("pageMeta", () => {
  const pageTitle = ref("");
  const menuLeftWidth = ref("0px");
  const forceHideMobileMenu = ref(false);

  const setPageTitle = (title, includeDashBd = true) => {
    pageTitle.value = title;
    document.title = title + (includeDashBd ? " - Business Draft" : "");
  };

  const $reset = () => {
    // Do nothing, this store should not be reset
  };

  return {
    pageTitle,
    menuLeftWidth,
    forceHideMobileMenu,
    setPageTitle,
    $reset,
  };
});
