import {setItem} from "../helpers/LocalStorage";
import {computed, ref} from "vue";
import CompanyApi from "../api/CompanyApi.js";
import EnterpriseApi from "../api/EnterpriseApi.js";

export const useLogInAs = (router) => {
  const loggingInAsCandidate = ref(false);
  const loggingInAsCompanyId = ref(false);
  const loggingInAsEnterpriseId = ref(false);

  const companyApi = new CompanyApi();
  const enterpriseApi = new EnterpriseApi();

  const isLoggingIn = computed(() => loggingInAsCandidate.value || loggingInAsCompanyId.value || loggingInAsEnterpriseId.value);

  const loginAsCandidate = async (handleSuccess) => {
    if (isLoggingIn.value) return;
    loggingInAsCandidate.value = true;

    // 1. Show the quick profile
    setItem("hideQuickProfile", false, 0.5);

    // 2. Log out of the other accounts
    const companyLoggedOut = await setActiveCompanyId(null);
    if (!companyLoggedOut) return loggingInAsCandidate.value = false;

    const enterpriseLoggedOut = await setActiveEnterpriseId(null);
    if (!enterpriseLoggedOut) return loggingInAsCandidate.value = false;

    // 3. Redirect to the correct dashboard
    await handleSuccess();

    // 4. Reset the loading state
    loggingInAsCandidate.value = false;
  };

  const loginAsCompany = async (companyId, billableEntityId, handleSuccess) => {
    if (isLoggingIn.value) return;
    loggingInAsCompanyId.value = companyId;

    // 1. Show the quick profile
    setItem("hideQuickProfileCompany", false, 0.5);

    // 2. Log out of other accounts
    const enterpriseLoggedOut = await setActiveEnterpriseId(null);
    if (!enterpriseLoggedOut) return loggingInAsCompanyId.value = false;

    // 3. Set the company
    const companyLoggedIn = await setActiveCompanyId(companyId, billableEntityId);
    if (!companyLoggedIn) return loggingInAsCompanyId.value = false;

    // 4. Redirect to the correct dashboard
    await handleSuccess();

    // 5. Reset the loading state
    loggingInAsCompanyId.value = false;
  };

  const loginAsEnterprise = async (enterpriseId, handleSuccess) => {
    if (isLoggingIn.value) return;
    loggingInAsEnterpriseId.value = enterpriseId;


    // 1. Log out of other accounts
    const companyLoggedOut = await setActiveCompanyId(null);
    if (!companyLoggedOut) return loggingInAsEnterpriseId.value = false;

    // 2. Set the enterprise
    const enterpriseLoggedIn = await setActiveEnterpriseId(enterpriseId);
    if (!enterpriseLoggedIn) return loggingInAsEnterpriseId.value = false;

    // 3. Redirect to the correct dashboard
    await handleSuccess();

    // 4. Reset the loading state
    loggingInAsEnterpriseId.value = false;
  };

  const setActiveCompanyId = async (companyId, billableEntityId = null) => {
    if (!companyId) companyId = 0;

    try {
      const response = await companyApi.setCompany(companyId);

      const success = response?.success || false;
      if (!success) toast.error("There was an issue logging into that account. Please contact support.");
      return success;
    } catch (e) {
      console.error(e);
      if (billableEntityId) await router.push({path: `/reinstate-payment/${billableEntityId}`});
      else toast.error("There was an issue logging into that account. Please contact support.");
    }
  };

  const setActiveEnterpriseId = async (enterpriseId) => {
    if (!enterpriseId) enterpriseId = 0;

    try {
      const response = await enterpriseApi.setEnterprise(enterpriseId);

      const success = response?.success || false;
      if (!success) toast.error("There was an issue logging into that account. Please contact support.");
      return success;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue logging into that account. Please contact support.");
    }
  };

  return {
    loginAsCandidate,
    loginAsCompany,
    loginAsEnterprise,
    isLoggingIn,
    loggingInAsCandidate,
    loggingInAsCompanyId,
    loggingInAsEnterpriseId,
  };
};
