<template>
  <div
    role="toolbar"
    :class="useGradientBanner ? 'text-white' : 'text-slate-900'"
    class="relative flex h-16 w-full items-center gap-1 px-4 md:px-6 lg:px-8"
    style="min-height: 4rem; order: 0">

    <MainMenuPageTitle
      :use-gradient-banner="useGradientBanner"
    />

    <p
      :class="useGradientBanner ? 'text-white' : 'text-slate-700'"
      class="mx-2 opacity-20 pointer-events-none">
      •
    </p>

    <MiniLaunchPad
      v-model:open="isMiniLaunchPadOpen"
      :use-gradient-banner="useGradientBanner"
      @update:open="(e) => toggleDropdown(DROPDOWN_TYPE_MINI_LAUNCH_PAD, e)"
    />

    <MainMenuNotificationIcon
      v-if="notificationLink"
      :use-gradient-banner="useGradientBanner"
      :notification-link="notificationLink"
    />

    <MainMenuSettingsIcon
      v-if="settingsLink"
      v-model:open="isSettingsDropdownOpen"
      :account-link="accountLink"
      :settings-link="settingsLink"
      :notification-link="notificationLink"
      :account-menu-items-count="accountMenuItems.length"
      @update:open="(e) => toggleDropdown(DROPDOWN_TYPE_SETTINGS, e)"
    />

    <MainMenuEnterpriseIcon
      v-if="isEnterprise"
      v-model:open="isEnterpriseDropdownOpen"
      :settings-link="settingsLink"
      :notification-link="notificationLink"
      :account-menu-items-count="accountMenuItems.length"
      @update:open="(e) => toggleDropdown(DROPDOWN_TYPE_ENTERPRISE, e)"
    />

    <MainMenuCompanyIcon
      v-if="isCompany"
      v-model:open="isCompanyDropdownOpen"
      :settings-link="settingsLink"
      :notification-link="notificationLink"
      @update:open="(e) => toggleDropdown(DROPDOWN_TYPE_COMPANY, e)"
    />

    <MainMenuAccountIcon
      v-model:open="isProfileDropdownOpen"
      :account-menu-items="accountMenuItems"
      @update:open="(e) => toggleDropdown(DROPDOWN_TYPE_PROFILE, e)"
    />

  </div>
</template>

<script setup>
import MiniLaunchPad from "./MiniLaunchPad.vue";
import MainMenuNotificationIcon from "./MainMenuNotificationIcon.vue";
import MainMenuSettingsIcon from "./MainMenuSettingsIcon.vue";
import MainMenuPageTitle from "./MainMenuPageTitle.vue";
import MainMenuEnterpriseIcon from "./MainMenuEnterpriseIcon.vue";
import MainMenuCompanyIcon from "./MainMenuCompanyIcon.vue";
import MainMenuAccountIcon from "./MainMenuAccountIcon.vue";
import {useAccountStore} from "../stores/AccountStore";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {LinkIcon, RocketLaunchIcon, SquaresPlusIcon, UserCircleIcon} from "@heroicons/vue/24/outline/index";
import {ArrowLeftOnRectangleIcon} from "@heroicons/vue/24/outline";

const props = defineProps({
  isCompany: Boolean,
  isEnterprise: Boolean,
  settingsLink: String,
  accountLink: String,
  notificationLink: String,
});

const accountStore = useAccountStore();
const route = useRoute();
const router = useRouter();

const DROPDOWN_TYPE_MINI_LAUNCH_PAD = "mini-launch-pad";
const DROPDOWN_TYPE_PROFILE = "profile";
const DROPDOWN_TYPE_COMPANY = "company";
const DROPDOWN_TYPE_ENTERPRISE = "enterprise";
const DROPDOWN_TYPE_SETTINGS = "settings";

const DROPDOWN_TYPES = [
  DROPDOWN_TYPE_MINI_LAUNCH_PAD,
  DROPDOWN_TYPE_PROFILE,
  DROPDOWN_TYPE_COMPANY,
  DROPDOWN_TYPE_ENTERPRISE,
  DROPDOWN_TYPE_SETTINGS
];

const isMiniLaunchPadOpen = ref(false);
const isProfileDropdownOpen = ref(false);
const isCompanyDropdownOpen = ref(false);
const isEnterpriseDropdownOpen = ref(false);
const isSettingsDropdownOpen = ref(false);

const useGradientBanner = computed(() => route.meta.useGradientBanner || false);

const toggleDropdown = (type, isOpening) => {
  if (!isOpening) return;
  closeAllDropdowns(type);
};

const closeAllDropdowns = (except = null) => {
  DROPDOWN_TYPES.forEach((dropdownType) => {
    if (dropdownType === except) return;

    switch (dropdownType) {
    case DROPDOWN_TYPE_MINI_LAUNCH_PAD:
      return isMiniLaunchPadOpen.value = false;
    case DROPDOWN_TYPE_PROFILE:
      return isProfileDropdownOpen.value = false;
    case DROPDOWN_TYPE_COMPANY:
      return isCompanyDropdownOpen.value = false;
    case DROPDOWN_TYPE_SETTINGS:
      return isSettingsDropdownOpen.value = false;
    case DROPDOWN_TYPE_ENTERPRISE:
      return isEnterpriseDropdownOpen.value = false;
    }
  });
};

const accountMenuItems = computed(() => {
  let hasCompanies = accountStore.user && accountStore.user?.companies;
  let hasReferrer = accountStore.user && accountStore.user?.referrer_id;

  return [
    ...(hasCompanies || hasReferrer ? [{
      name: "Launch Pad",
      link: "/launch-pad",
      icon: RocketLaunchIcon,
    }] : []),
    ...(props.isCompany ? [{
      name: "Integrations",
      link: "/integrations",
      icon: LinkIcon,
    }] : []),
    ...(props.isCompany ? [{
      name: "Widgets",
      link: "/widgets",
      icon: SquaresPlusIcon,
    }] : []),
    ...(props.accountLink ? [{
      name: "Account",
      link: props.accountLink,
      icon: UserCircleIcon,
    },] : []),
    {
      name: "Logout",
      link: "/logout",
      icon: ArrowLeftOnRectangleIcon,
    },
  ];
});

onMounted(() => router.afterEach(() => closeAllDropdowns()));
</script>
