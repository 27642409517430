import pluralize from "pluralize-esm";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const formatTimestamp = (timestamp) => {
  // Format a string timestamp into a human-readable date.
  if (!timestamp) return "";
  let date = new Date(timestamp);
  let M = months[date.getMonth()];
  let d = date.getDate();
  let yyyy = date.getFullYear();
  return M + " " + d + ", " + yyyy;
};

export const formatYearMonthDate = (year,month,date) => {
  let M = months[month - 1];
  return M + " " + date + ", " + year;
};

export const formatTimestampWithTime = (timestamp) => {
  if (!timestamp) return "";

  let date = new Date(timestamp);

  const day = date.getDate();
  let suffix = "th";
  if (day % 10 === 1 && day !== 11) suffix = "st";
  else if (day % 10 === 2 && day !== 12) suffix = "nd";
  else if (day % 10 === 3 && day !== 13) suffix = "rd";

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  let formatted = new Intl.DateTimeFormat("en-US", options).format(date);
  return formatted.replace(`${day},`, `${day}${suffix},`);
};

export const daysReadableSinceTimestamp = (timestamp) => {
  if (!timestamp) return "";

  const date = new Date(timestamp);
  const now = new Date();
  const elapsed = now - date;

  // Define time intervals in milliseconds
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  if (elapsed < minute) {
    return "just now";
  } else if (elapsed < hour) {
    const minutes = Math.floor(elapsed / minute);
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else if (elapsed < day) {
    const hours = Math.floor(elapsed / hour);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (elapsed < week * 2) {
    const days = Math.floor(elapsed / day);
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (elapsed < month) {
    const weeks = Math.floor(elapsed / week);
    return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
  } else if (elapsed < year) {
    const months = Math.floor(elapsed / month);
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else {
    const years = Math.floor(elapsed / year);
    return `${years} ${years === 1 ? "year" : "years"} ago`;
  }
};

export const timeReadableSinceTimestamp = (timestamp) => {
  if (timestamp && timestamp[timestamp.length - 1] !== "Z") timestamp += "Z"; // Must be UTC
  const currentTime = new Date();
  const inputTime = new Date(timestamp);

  // Get exact time if today
  if (inputTime.getFullYear() === currentTime.getFullYear() &&
    inputTime.getMonth() === currentTime.getMonth() &&
    inputTime.getDate() === currentTime.getDate()) {
    let hours = inputTime.getHours();
    const minutes = ("0" + inputTime.getMinutes()).slice(-2);  // ensure two digits
    const period = hours >= 12 ? "pm" : "am";

    hours %= 12;
    if (hours === 0) hours = 12;

    return `${hours}:${minutes}${period}`;
  }

  // Get text "Yesterday" if yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (inputTime.getFullYear() === yesterday.getFullYear() &&
    inputTime.getMonth() === yesterday.getMonth() &&
    inputTime.getDate() === yesterday.getDate()) {
    return "Yesterday";
  }

  // Get text "x time ago" otherwise
  return daysReadableSinceTimestamp(timestamp);
};

const optimizedNow = new Date();
const optimizedNowTime = optimizedNow.getTime();
export const daysSinceTimestamp = (timestamp, relativeTo) => {
  if (!timestamp) return 0;
  if (relativeTo) {
    return Math.floor(
      (optimizedNowTime - new Date(timestamp).getTime()) / 86400000
    ); // 86,400,000 ms per day
  }
  const now = new Date();
  const nowTime = now.getTime();
  const date = new Date(timestamp).getTime();
  const elapsed = nowTime - date;
  return Math.floor(elapsed / 86400000); // 86,400,000 ms per day
};

export const differenceBetweenDates = (start, end, unit = "days") => {
  if (!start) return 0;
  let date1, date2;
  if (start instanceof Date) {
    date1 = start;
  } else {
    date1 = new Date(start);
  }
  if (!end) end = new Date();
  if (end instanceof Date) {
    date2 = end;
  } else {
    date2 = new Date(end);
  }

  let diffDays = Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24); // difference in days

  if (unit === "years") return diffDays / 365;
  return diffDays;
};

export const formatDaysReadable = (days) => {
  if (!days) return "0 days";
  if (days < 32) return Math.round(days * 10) / 10 + " days";
  if (days < 365) return Math.round((days / 30) * 10) / 10 + " months";
  return Math.round((days / 365) * 10) / 10 + " years";
};

export const formatTenure = (start, end) => {
  if (!start && !end) return "";

  if (start && start.length === 7) start += "-01";
  if (end && end.length === 7) end += "-01";

  if (start && start.length === 10) start += "T00:00";
  if (end && end.length === 10) end += "T00:00";

  let startDate = start ? new Date(start) : null;
  let endDate = end ? new Date(end) : null;

  if (!start) return `Until ${months[endDate.getMonth()]} ${endDate.getFullYear()}`;
  if (!end) return `Since ${months[startDate.getMonth()]} ${startDate.getFullYear()}`;
  return `${months[startDate.getMonth()]} ${startDate.getFullYear()} - ${
    months[endDate.getMonth()]
  } ${endDate.getFullYear()}`;
};

// e.x. 2021-01-01T00:00:00.000Z => 1/1/21
export const formatDateMinimal = (date) => {
  if (!date) return "";
  let d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let year = d.getFullYear().toString().slice(-2);
  return month + "/" + day + "/" + year;
};

// e.x. 2021-01-01T00:00:00.000Z => Jan 1, 2021
export const formatDateShort = (date) => {
  if (!date) return "";
  let d = new Date(date);
  let month = months[d.getMonth()];
  let day = d.getDate();
  let year = d.getFullYear();
  return month + " " + day + ", " + year;
};

// e.x. 2021-01-01T00:00:00.000Z => Jan 1, 21 at 1:00am
export const formatDateMedium = (date) => {
  if (!date) return "";
  let d = new Date(date);
  let month = months[d.getMonth()];
  let day = d.getDate();
  let year = d.getFullYear().toString().slice(-2);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return month + " " + day + ", " + year + " at " + hours + ":" + minutes + ampm;
};

// e.x. "2021-01-01T00:00:00.000Z" => "Jan 1, 2021 at 12:00 AM"
export const formatDateLong = (date) => {
  if (!date) return "";
  let d = new Date(date);
  let month = months[d.getMonth()];
  let day = d.getDate();
  let year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return month + " " + day + ", " + year + " at " + strTime;
};

// e.x. 2 => 2 minutes, 60 => 1 hour, 1440 => 1 day
export const formatMinutesReadable = (minutes) => {
  minutes = Math.round(minutes);

  if (minutes < 240) return minutes + " " + pluralize("minute", minutes);

  if (minutes < 1440) {
    let hours = Math.round(minutes / 60);
    return hours + " " + pluralize("hour", hours);
  }

  let days = Math.round(minutes / 1440);
  return days + " " + pluralize("day", days);
};
