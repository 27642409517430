<template>
  <div :class="background"
       class="inline-block w-full border-b-2 border-white/50 px-4 py-2 text-center text-sm md:py-2.5 lg:px-6">

    <span :class="text">
      <slot/>
    </span>

    <button type="button"
            class="ml-2 underline opacity-80 transition-all text hover:opacity-100"
            @click="dismiss">
      Dismiss
    </button>

  </div>
</template>

<script setup>
import {computed} from "vue";

const emits = defineEmits(["dismiss"]);
const props = defineProps({
  variant: {
    type: String,
    default: "info",
    validator: (value) => ["info", "success", "warning", "error"].includes(value),
  },
  content: String,
});

const backgroundMap = {
  info: "bg-gradientExtraFaint",
  success: "bg-green-600 text-white",
  warning: "bg-yellow-500 text-white",
  error: "bg-red-500 text-white",
};

const textMap = {
  info: "text-gradient font-bold",
  success: "text-white font-medium",
  warning: "text-white font-medium",
  error: "text-white font-medium",
};

const background = computed(() => backgroundMap[props.variant]);
const text = computed(() => textMap[props.variant]);

const dismiss = () => emits("dismiss");
</script>
