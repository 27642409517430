<template>
  <div>
    <div class="spinning-loader" :style="styles"/>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  diameter: {
    type: String,
    default: "24px",
  },
  borderSize: {
    type: String,
    default: "3px",
  },
  color: {
    type: String,
    default: "#fff",
  },
});

const styles = computed(() => {
  return `--loader-diameter:${props.diameter};--loader-border-size:${props.borderSize};--loader-color:${props.color};`;
});
</script>

<style scoped>
.spinning-loader {
  width: var(--loader-diameter);
  height: var(--loader-diameter);
  border-radius: 50%;
  position: relative;
  animation: rotateSpinningLoader 1s linear infinite
}

.spinning-loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: var(--loader-border-size) solid var(--loader-color, #fff);
  animation: prixClipFix 2s linear infinite;
}

@keyframes circleRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateSpinningLoader {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}
</style>
