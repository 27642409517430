<template>
  <OnClickOutside
    @trigger="() => updateOpen(false)">

    <button
      type="button"
      class="ml-3 hidden md:block"
      @click="() => updateOpen(!props.open)">

      <UtilityProfileInitials
        size="md"
        :name="accountStore.name"
        :avatar="accountStore.user?.avatar || ''"
        class="cursor-pointer"/>

    </button>


      <TransitionDropdown>
        <div
          v-if="open"
          @click="() => updateOpen(!props.open)"
          class="absolute top-16 right-8 z-50 flex flex-col items-start rounded bg-white px-6 text-slate-500 shadow md:block">

          <template
            v-for="(item, index) in accountMenuItems" :key="item.link">

            <RouterLink
              :to="item.link"
              class="flex items-center justify-start gap-3 py-3.5 hover:text-slate-900">
              <Component
                :is="item.icon"
                class="h-4 w-4"/>
              {{ item.name }}
            </RouterLink>

            <div
              v-if="index !== accountMenuItems.length - 1"
              class="h-px w-full bg-slate-100"/>

          </template>
        </div>
      </TransitionDropdown>

  </OnClickOutside>
</template>

<script setup>
import {OnClickOutside} from "@vueuse/components";
import UtilityProfileInitials from "./UtilityProfileInitials.vue";
import {useAccountStore} from "../stores/AccountStore";
import TransitionDropdown from "./TransitionDropdown.vue";

const emits = defineEmits(["update:open"]);
const props = defineProps({
  open: Boolean,
  accountMenuItems: Array,
});

const accountStore = useAccountStore();

const updateOpen = (value) => emits("update:open", value);
</script>
