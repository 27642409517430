import {ENTERPRISE_TYPE_CORPORATE_CHAIN, ENTERPRISE_TYPE_STAFFING_AGENCY} from "../constants/EnterpriseTypes";

/**
 * See also EnterpriseType.php Enum
 *
 * @param type
 * @returns {{groupNameLabel: string, groupSingular: string, companyNamePlaceholder: string, companyNamePlural: string, companyNameSingular: string, groupOwner: string, groupPlural: string, typeName: string, groupNamePlaceholder: string}}
 */
export const getEnterpriseNouns = (type) => {
  switch (type) {
  case ENTERPRISE_TYPE_CORPORATE_CHAIN:
    return {
      groupPlural: "Regions",
      groupSingular: "Region",
      groupNameLabel: "Region Name",
      groupNamePlaceholder: "e.g. Northwest Region",
      groupOwner: "Regional Manager",
      companyNamePlural: "Locations",
      companyNameSingular: "Location",
      companyNamePlaceholder: "e.g. Seattle Store",
      typeName: "Corporation",
    };
  case ENTERPRISE_TYPE_STAFFING_AGENCY:
    return {
      groupPlural: "Clients",
      groupSingular: "Client",
      groupNameLabel: "Client Name",
      groupNamePlaceholder: "e.g. Acme Corp",
      groupOwner: "The Client Themselves",
      companyNamePlural: "Client Accounts",
      companyNameSingular: "Client Account",
      companyNamePlaceholder: "e.g. Acme Corp - Main Office",
      typeName: "Staffing Agency",
    };
  default:
    return {
      groupPlural: "Franchisees",
      groupSingular: "Franchisee",
      groupNameLabel: "Franchisee Owner Name",
      groupNamePlaceholder: "e.g. Vandelay Holdings or George Costanza",
      groupOwner: "Franchisee Owner",
      companyNamePlural: "Locations",
      companyNameSingular: "Location",
      companyNamePlaceholder: "e.g. Constanza Corner",
      typeName: "Franchise",
    };
  }
};
