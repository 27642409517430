<template>
  <template v-if="isAllowed">
    <slot/>
  </template>
  <template v-else>
    <slot name="blocked"/>
  </template>
</template>

<script setup>
import {useAccountStore} from "../stores/AccountStore.js";
import {computed} from "vue";
import {
  COMPANY_ADMIN,
  ENTERPRISE_ADMIN, ENTERPRISE_OWNER,
  ENTERPRISE_VIEWER,
  MANAGER,
  RECRUITER,
  TEAM_MEMBER
} from "../constants/Roles.js";

const accountStore = useAccountStore();

const props = defineProps({
  roles: {
    type: Array,
    required: true,
    validator: (value) => {
      const validRoles = [COMPANY_ADMIN, MANAGER, RECRUITER, TEAM_MEMBER, ENTERPRISE_VIEWER, ENTERPRISE_ADMIN, ENTERPRISE_OWNER];
      return value.every(role => validRoles.includes(role));
    }
  },
});

const isAllowed = computed(() => {
  const activeCompanyRole = accountStore.user?.activeCompany?.role || "";
  const activeEnterpriseRole = accountStore.user?.activeEnterprise?.role || "";
  return props.roles.includes(activeCompanyRole) || props.roles.includes(activeEnterpriseRole);
});
</script>
