import BaseApi from "./BaseApi";
import axios from "axios";

class CompanyApi extends BaseApi {
  constructor() {
    super("companies");
  }

  checkIfEmailExists = (data) => axios.post(`${this.baseUrl}/check-email-exists`, data);
  setCompany = (id) => axios.put(`${this.baseUrl}/set/${id}`);
  activateTrial = (token) => axios.post(`${this.baseUrl}/activate-trial`, { token });
}

export default CompanyApi;
