export const LOCATION_WEB_CONFERENCE = "web_conference";
export const LOCATION_IN_PERSON = "in_person";
export const LOCATION_PHONE = "phone";
export const LOCATION_UNKNOWN = "unknown";
export const NEEDS_SCORES = "needs_scores";

export const LOCATIONS = [
  LOCATION_WEB_CONFERENCE,
  LOCATION_IN_PERSON,
  LOCATION_PHONE,
];

export const STATUS_NOT_SCHEDULED = "Not Scheduled";
export const STATUS_SCHEDULED = "Scheduled";
export const STATUS_COMPLETED = "Completed";
export const STATUS_CANCELLED = "Cancelled";
export const STATUS_DISQUALIFIED = "Disqualified";
export const STATUS_NO_SHOW = "No Show";

export const STATUSES = [
  STATUS_NOT_SCHEDULED,
  STATUS_SCHEDULED,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_DISQUALIFIED,
];

export const MENU_ITEM_AUDIO = "audio";
export const MENU_ITEM_VIDEO = "video";
export const MENU_ITEM_CANDIDATE_PROFILE = "candidateProfile";
export const MENU_ITEM_COMPANY_PROFILE = "companyProfile";
export const MENU_ITEM_CANDIDATE_SCORES = "candidateScores";
export const MENU_ITEM_COMPANY_SCORES = "companyScores";
export const MENU_ITEM_CHAT = "chat";
export const MENU_ITEM_RECORDING = "recording";
export const MENU_ITEM_PARTICIPANTS = "participants";
export const MENU_ITEM_SHARE_SCREEN = "shareScreen";
export const MENU_ITEM_NOTES = "notes";
export const MENU_ITEM_SETTINGS = "settings";
export const MENU_ITEM_MORE = "more";

export const INTERVIEW_RECORDING_PREFERENCE_ALWAYS = "always";
export const INTERVIEW_RECORDING_PREFERENCE_NEVER = "never";
export const INTERVIEW_RECORDING_PREFERENCE_ASK = "ask";

export const PARTICIPANT_GROUP_INTERVIEWEE = "Interviewee";
export const PARTICIPANT_GROUP_INTERVIEWER = "Interviewer";
export const PARTICIPANT_GROUP_HIRING_MANAGER = "Hiring Manager";
export const PARTICIPANT_GROUP_COMPANY_ADMIN = "Company Admin";
export const NEXT_STEP_NO_DECISION = "no-decision";
export const NEXT_STEP_READY = "next-step";
export const NEXT_STEP_READY_IMMEDIATE = "next-step-immediately";
export const NEXT_STEP_DISQUALIFY = "disqualify";
export const PREVIOUS_STEP = "previous-step";

export const VALID_NEXT_STEPS = [
  NEXT_STEP_NO_DECISION,
  NEXT_STEP_READY_IMMEDIATE,
  NEXT_STEP_READY,
  NEXT_STEP_DISQUALIFY,
];
