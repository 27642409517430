<template>
  <MenuContainer
    :menu-items="menuItems"
    dashboardLink="/dashboard"
    notificationLink="/notifications"
    settingsLink="/notification-preferences"
    accountLink="/account">
    <slot/>
  </MenuContainer>
</template>

<script setup>
import MenuContainer from "../components/MenuContainer.vue";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  HomeIcon,
  RectangleStackIcon,
  UserIcon
} from "@heroicons/vue/24/outline";
import {
  BriefcaseIcon as BriefcaseIconSolid,
  CalendarDaysIcon as CalendarDaysIconSolid,
  ChatBubbleOvalLeftEllipsisIcon as ChatBubbleOvalLeftEllipsisIconSolid,
  HomeIcon as HomeIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  UserIcon as UserIconSolid,
} from "@heroicons/vue/24/solid";

const menuItems = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: HomeIcon,
    iconSolid: HomeIconSolid,
  },
  {
    name: "Opportunities",
    link: "/opportunities",
    icon: BriefcaseIcon,
    iconSolid: BriefcaseIconSolid,
  },
  {
    name: "My Applications",
    link: "/applications",
    icon: RectangleStackIcon,
    iconSolid: RectangleStackIconSolid,
  },
  {
    name: "Interviews",
    link: "/interviews",
    icon: CalendarDaysIcon,
    iconSolid: CalendarDaysIconSolid,
  },
  {
    name: "Messages",
    link: "/messages",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    iconSolid: ChatBubbleOvalLeftEllipsisIconSolid,
  },
  {
    name: "My Profile",
    link: "/profile",
    icon: UserIcon,
    iconSolid: UserIconSolid,
  },
];
</script>
