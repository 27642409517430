<template>
  <RouterLink
    :to="link"
    class="ring-offset-menu"
    :class="linkClasses">
    <Component :is="icon" class="h-5 w-5 min-w-[1.25rem]"/>
    <span :class="labelClasses">
      {{ name }}
    </span>
    <span v-if="isBeta && isOpen"
          class="rounded-full px-1.5 text-xxs font-medium text-white py-0.5 bg-white bg-opacity-20">Beta</span>
  </RouterLink>
</template>

<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();
const props = defineProps({
  icon: Function,
  name: String,
  link: String,
  isOpen: Boolean,
  isBeta: Boolean,
});

const linkClasses = computed(() => [
  "flex h-24 flex-col flex-nowrap items-center justify-center gap-3 rounded-lg px-5 transition-all w-[45%] text-menuText lg:w-[30%] xl:h-14 xl:w-full xl:flex-row xl:justify-start",
  route.fullPath.includes(props.link) ? "opacity-100 bg-menuText bg-opacity-10" : "opacity-50 bg-menuText bg-opacity-0 hover:bg-opacity-5",
  !props.isOpen ? "xl:justify-center xl:px-0" : ""
]);

const labelClasses = computed(() => [
  "inline-block whitespace-nowrap text-base",
  !props.isOpen && "xl:hidden"
]);
</script>
