import {defineStore} from "pinia";
import {objectToCamelCase} from "../helpers/CaseTransformer.js";
import {computed, nextTick, ref} from "vue";
import {setItem} from "../helpers/LocalStorage.js";
import {gtagSetUser} from "../helpers/GoogleAnalytics.js";
import AccountApi from "../api/AccountApi";

export const useAccountStore = defineStore("account", () => {
  const user = ref();
  const userChannel = ref();
  const intercomLoaded = ref(false);

  const isLoggedIn = computed(() => !!user.value?.id);
  const name = computed(() => (user.value?.firstName || "") + " " + (user.value?.lastName || ""));
  const hasUserChannel = ref(false);

  const login = async (email, password, rememberMe = false, setCompanyId = null) => {
    if (window.Intercom) window.Intercom("shutdown");

    await AccountApi.getCsrfToken();
    const response = await AccountApi.login(email, password, rememberMe, setCompanyId);
    if (!response.data) return response;

    user.value = objectToCamelCase(response.data);
    bootstrapUserAccount();

    setItem("rememberEmail", rememberMe ? email : "", 30);

    return response;
  };

  const logout = async () => {
    leaveUserChannel();
    await AccountApi.getCsrfToken();
    await AccountApi.logout();
    if (window.Intercom) window.Intercom("shutdown");
    stopSessionPulse();
    user.value = null;
  };

  const twoFactorChallenge = async (data) => {
    return await AccountApi.twoFactorChallenge(data);
  };

  const forgotPassword = async (email) => {
    await AccountApi.forgotPassword(email);
  };

  const resetPassword = async (password, password_confirmation, email, token) => {
    await AccountApi.resetPassword({
      password,
      password_confirmation,
      email,
      token,
    });
  };

  const fetchUserAccount = async () => {
    try {
      const response = await AccountApi.getUserData();
      user.value = objectToCamelCase(response.data);
      bootstrapUserAccount();
      console.info("🔑 User Account Fetched");
    } catch (e) {
      console.error(e);
    }
  };

  const bootstrapUserAccount = () => {
    if (!isLoggedIn.value) return;

    joinUserChannel();
    startSessionPulse();
    handleChangeUser();
    gtagSetUser(user.value.id);
  };

  const joinUserChannel = () => {
    if (!isLoggedIn.value) return;
    userChannel.value = window.Echo.private(`App.Models.User.${user.value.id}`);
    hasUserChannel.value = true;
  };

  const leaveUserChannel = () => {
    if (!isLoggedIn.value) return;
    window.Echo.leave(`App.Models.User.${user.value.id}`);
    userChannel.value = null;
    hasUserChannel.value = false;
  };

  const refreshIntercom = async (method = "boot", additionalUserData = {}) => {
    const intercomAppId = "" + (import.meta.env.VITE_INTERCOM_APP_ID || "");

    if (!intercomAppId || location.hostname !== "businessdraft.com" || location.protocol !== "https:" || !isLoggedIn.value) return; // Intercom has no localhost support, staging messes with user ids

    await nextTick(); // Wait for #intercom-launcher to be rendered

    if (window.Intercom) window.Intercom(method, {
      app_id: intercomAppId,
      user_id: user.value?.id,
      user_hash: user.value?.intercomUserHash,
      email: user.value?.email,
      hide_default_launcher: true,
      custom_launcher_selector: "#intercom-launcher",
      ...additionalUserData,
    });

    intercomLoaded.value = true;
  };

  let sessionCheckInterval = null;

  const startSessionPulse = () => {
    if (sessionCheckInterval) stopSessionPulse();
    window.addEventListener("focus", sessionPulseCheck);

    // Check session is logged in every 10 minutes, but wait 10 minutes before starting
    setTimeout(() => {
      sessionCheckInterval = setInterval(sessionPulseCheck, 600000); // 10 minutes
    }, 600000); // 10 minutes
  };

  const stopSessionPulse = () => {
    window.removeEventListener("focus", sessionPulseCheck);
    clearInterval(sessionCheckInterval);
  };

  let lastSessionPulseAt = null;

  const sessionPulseCheck = () => {
    if (lastSessionPulseAt && (new Date() - lastSessionPulseAt) < 60 * 1000) return; // max 1 per minute
    lastSessionPulseAt = new Date();

    new AccountApi()
      .checkSession()
      .then(async response => {
        const {is_active, user_id} = response;
        if (is_active === "active" && user_id === user.value.id) return;
        setItem("targetUrl", window.location.href, 4);
        await logout();
      });
  };

  const handleChangeUser = async () => {
    const createdAt = new Date(user.value.created_at);
    const createdAtInSeconds = Math.floor(createdAt.getTime() / 1000);

    await refreshIntercom(intercomLoaded.value ? "update" : "boot", {
      name: name.value,
      created_at: createdAtInSeconds,
      "Number of Enterprises": user.value?.enterprises?.length || 0,
      "Number of Companies": user.value?.companies?.length || 0,
      "Current Company": user.value?.activeCompany?.name || "None",
      "Current Enterprise": user.value?.activeEnterprise?.name || "None",
    });
  };

  const routerAfterEach = async () => {
    if (!intercomLoaded.value) return;
    await refreshIntercom("update");
  };

  const $reset = () => {
    // Do nothing, this store should not be reset ever
  };

  return {
    user,
    userChannel,
    hasUserChannel,
    isLoggedIn,
    name,
    login,
    logout,
    twoFactorChallenge,
    forgotPassword,
    resetPassword,
    fetchUserAccount,
    joinUserChannel,
    leaveUserChannel,
    routerAfterEach,
    $reset,
  };
});
