export const OPPORTUNITY_INVITE = "Opportunity Invite";
export const OPPORTUNITY_POSTED = "Opportunity Posted (Per Month)";
export const USER_SEAT = "User Seat (Per Month)";

export const TYPES = [
  OPPORTUNITY_INVITE,
  OPPORTUNITY_POSTED,
  USER_SEAT,
];

export const IMMEDIATE_THEN_RECURRING_MONTHLY = "immediate_then_recurring_monthly";
export const MONTHLY_ON_CYCLE_DAY = "monthly_on_cycle_day";
export const ON_BILLING_CYCLE = "on_billing_cycle";

export const CYCLE_INTERVAL_MONTH = "month";
export const CYCLE_INTERVAL_YEAR = "year";

export const UNLIMITED_BILLING_PRICE_THRESHOLD = 1_000_000;

export const STATUS_PAID = "paid";
export const STATUS_GENERATING = "generating";
export const STATUS_PARTIALLY_PAID = "partially_paid";
export const STATUS_ERROR = "error";
export const STATUS_MISSING_ADDRESS = "missing_address";
export const STATUS_MISSING_PAYMENT_METHOD = "missing_payment_method";
export const STATUS_PAYMENT_FAILED = "payment_failed";
export const STATUS_REFUNDED = "refunded";
export const STATUS_PARTIAL_REFUNDED = "partial_refunded";
export const UNPAID_STATUSES = [
  STATUS_MISSING_ADDRESS,
  STATUS_MISSING_PAYMENT_METHOD,
  STATUS_PAYMENT_FAILED,
];
export const GRACE_PERIOD_IN_DAYS_FOR_PAYING_CUSTOMER_WITH_CARD_DECLINED = 10;

const CANCELLATION_REASON_TOO_EXPENSIVE = "too_expensive";
const CANCELLATION_REASON_NEED_MORE_FEATURES = "need_more_features";
const CANCELLATION_REASON_FOUND_ALTERNATIVE = "found_alternative";
const CANCELLATION_REASON_NO_LONGER_NEEDED = "no_longer_needed";
const CANCELLATION_REASON_LESS_THAN_EXPECTED_SERVICE = "less_than_expected_service";
const CANCELLATION_REASON_LESS_THAN_EXPECTED_EASE = "less_than_expected_ease";
const CANCELLATION_REASON_LESS_THAN_EXPECTED_QUALITY = "less_than_expected_quality";

export const PRESET_CANCELLATION_REASONS = [
  {
    name: "It's too expensive",
    value: CANCELLATION_REASON_TOO_EXPENSIVE,
  },
  {
    name: "I need more features",
    value: CANCELLATION_REASON_NEED_MORE_FEATURES,
  },
  {
    name: "I found an alternative",
    value: CANCELLATION_REASON_FOUND_ALTERNATIVE,
  },
  {
    name: "I no longer need it",
    value: CANCELLATION_REASON_NO_LONGER_NEEDED,
  },
  {
    name: "Customer service was less than expected",
    value: CANCELLATION_REASON_LESS_THAN_EXPECTED_SERVICE,
  },
  {
    name: "Ease of use was less than expected",
    value: CANCELLATION_REASON_LESS_THAN_EXPECTED_EASE,
  },
  {
    name: "Quality was less than expected",
    value: CANCELLATION_REASON_LESS_THAN_EXPECTED_QUALITY,
  },
];

export const LOCKED_REASON_INVOICE_OVERDUE = "invoice_overdue";
export const LOCKED_REASON_CANCELLED = "cancelled";
export const LOCKED_REASON_DELETED = "deleted";

export const LOCKED_REASONS = [
  {
    name: "Invoice Overdue",
    solution: "Settle Invoices to Unlock Your Account",
    value: LOCKED_REASON_INVOICE_OVERDUE,
  },
  {
    name: "Account Cancelled",
    solution: "Re-subscribe to Business Draft",
    value: LOCKED_REASON_CANCELLED,
  },
  {
    name: "Account Deleted",
    solution: "Re-subscribe to Business Draft",
    value: LOCKED_REASON_DELETED,
  },
];

export const CARDS = {
  visa: {
    name: "Visa",
    icon: "https://resources.businessdraft.com/visa.svg"
  },
  mastercard: {
    name: "Mastercard",
    icon: "https://resources.businessdraft.com/mastercard.svg"
  },
  amex: {
    name: "American Express",
    icon: "https://resources.businessdraft.com/amex.svg"
  },
  discover: {
    name: "Discover",
    icon: "https://resources.businessdraft.com/discover.svg"
  },
  diners: {
    name: "Diners Club",
    icon: "https://resources.businessdraft.com/diners.svg"
  },
  jcb: {
    name: "Japan Credit Bureau",
    icon: "https://resources.businessdraft.com/jcb.svg"
  },
  unionpay: {
    name: "UnionPay",
    icon: "https://resources.businessdraft.com/unionpay.svg"
  },
  alipay: {
    name: "Alipay",
    icon: "https://resources.businessdraft.com/alipay.svg"
  },
  elo: {
    name: "Elo",
    icon: "https://resources.businessdraft.com/elo.svg"
  },
  hipercard: {
    name: "Hipercard",
    icon: "https://resources.businessdraft.com/hipercard.svg"
  },
  maestro: {
    name: "Maestro",
    icon: "https://resources.businessdraft.com/maestro.svg"
  },
  mir: {
    name: "Mir",
    icon: "https://resources.businessdraft.com/mir.svg"
  },
  paypal: {
    name: "PayPal",
    icon: "https://resources.businessdraft.com/paypal.svg"
  },
  unknown: {
    name: "Unknown",
    icon: "https://resources.businessdraft.com/generic.svg"
  }
};
