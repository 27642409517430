<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.77 144">
    <defs>
      <linearGradient id="lg" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#18c0f3"/>
        <stop offset="0.299" stop-color="#8f3f98"/>
        <stop offset="0.6" stop-color="#f54160"/>
        <stop offset="0.798" stop-color="#f36e40"/>
        <stop offset="1" stop-color="#faac31"/>
      </linearGradient>
      <linearGradient id="lg-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#5a79c1"/>
        <stop offset="0.274" stop-color="#9d3f90"/>
        <stop offset="0.583" stop-color="#e43e6b"/>
        <stop offset="0.796" stop-color="#f45155"/>
        <stop offset="1" stop-color="#f3703f"/>
      </linearGradient>
    </defs>
    <g transform="translate(-67 -352.816)">
      <path
        d="M2155,312.887V456.648s59.709.379,88.247,0,50.352-11.868,51.486-38.692-24.134-34.142-24.134-34.142a46.519,46.519,0,0,1-1.326,12.412,50.067,50.067,0,0,1-3.853,10.127,20.341,20.341,0,0,1,3.853,11.6c-.059,6.626-2.424,19.819-26.025,19.916s-68.471,0-68.471,0V331.79h68.471c13.49,0,25.142.91,29.661,12.536s-7.489,18.889-7.489,18.889a24.146,24.146,0,0,1,3.427,7.43,46.978,46.978,0,0,1,1.753,9.222c7.608-5.193,22.031-13.826,21.374-33.332s-14.364-34.1-48.725-33.711S2155,312.887,2155,312.887Z"
        transform="translate(-2088 40)" fill="url(#lg)"/>
      <path
        d="M2225.281,425.713l.087-42.078s7.291-.021,35.057-.021,50.07,17.834,50.07,43.7-21.208,44.908-50.07,44.908H2225.22l.062-43.377,64.626.169c-.135,6.772-4.648,12.4-4.648,12.4h-43.931v12.473h19.189c16.218,0,32.871-9.512,31.974-26.577s-15.819-25.647-32.067-25.659-19.1,0-19.1,0v11.611h42.457s4.653,3.31,6.122,12.446Z"
        transform="translate(-2130.788 -3.14)" fill="url(#lg-2)"/>
    </g>
  </svg>
</template>
